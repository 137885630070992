import React, { FC, useEffect, useState } from 'react';
import './index.less';
import { ReactComponent as IconDelete } from '@/assets/svg/icon_delete.svg';
import { ReactComponent as IconEdit } from '@/assets/svg/icon_edit_linear.svg';
import { Button, Modal } from 'antd';
import MDEditor from '@uiw/react-md-editor';
import { ReactComponent as AddIcon } from '@/assets/svg/add_icon.svg';
import UploadKnowledgeFile from './UploadKnowledgeFile';
import { getknowledgebaseDetail, deleteknowledgebase } from '@/features/knowledge/actions';
import { useAppDispatch } from '@/features/hooks';

import moment from 'moment';
import AddUrl from './AddUrl';
interface IPropTypes {
  status: string;
  className?: string;
  knowledgeId: string;
  tabs: string;
  onEditChange: (val: boolean, id?: string) => void;
}

const DocumentsDetail: FC<IPropTypes> = ({ knowledgeId, onEditChange, status, tabs }) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>(null);
  const [tags, setTags] = useState<string[]>([]);

  const handleEntry = () => {
    onEditChange(true, '');
  };
  const handleEdit = () => {
    onEditChange(true, knowledgeId);
  };
  useEffect(() => {
    async function fetchKnowledgeDetail() {
      try {
        const result = await dispatch(
          getknowledgebaseDetail({
            knowledge_id: knowledgeId,
          }),
        );
        setData(result.payload.data);
        setTags(result.payload.data?.tags || []);
      } catch (error) {
        console.error('Error fetching knowledge base detail:', error);
      }
    }

    if (knowledgeId) {
      fetchKnowledgeDetail();
    }
  }, [dispatch, knowledgeId]);
  const handleDeleteClick = () => {
    Modal.confirm({
      title: 'Confirm Deletion',
      content: 'Are you sure you want to delete the current knowledge block?',
      onOk() {
        dispatch(deleteknowledgebase({ knowledgeId }));
        const id = knowledgeId ? knowledgeId : '';
        onEditChange(false, '');
      },
      okText: 'Yes',
      cancelText: 'No',
      className: 'delete-confirm-modal',
    });
  };
  return (
    <div style={{ height: '100%' }}>
      {!knowledgeId && (
        <div>
          {tabs == 'documents' ? (
            <div className="cor-documentsDetail cor-documentsDetail-empty">
              <div className="cor-documentsDetail-empty-title">You have no published knowledge entries yet.</div>
              <div className="cor-documentsDetail-empty-btn">
                <Button icon={<AddIcon />} onClick={handleEntry}>
                  Type Entry
                </Button>
                <UploadKnowledgeFile />
              </div>
            </div>
          ) : (
            <div className="cor-documentsDetail cor-documentsDetail-empty">
              <div className="cor-documentsDetail-empty-title">You have no published URLs yet.</div>
              <div className="cor-documentsDetail-empty-btn">
              <AddUrl/>
              </div>
            </div>
          )}
        </div>
      )}
      {knowledgeId && data && (
        <div className="cor-documentsDetail">
          <div className="cor-documentsDetail-head">View {status} Entry</div>
          <div className="cor-documentsDetail-center">
            <div>
              <div className="cor-documentsDetail-center-title">{data.title}</div>
            </div>
            <div className="cor-documentsDetail-center-xian"></div>
            <MDEditor.Markdown className="cor-documentsDetail-center-message" source={data.content} />
            <div className="cor-documentsDetail-center-tags">
              <div className="cor-documentsDetail-center-tags-left">Tags:</div>
              <div className="cor-documentsDetail-center-tags-right">
                {data.tags.map((tag: { name: string }, index: number) => (
                  <div key={index}>{tag.name}</div>
                ))}
              </div>
            </div>
          </div>
          <div className="cor-documentsDetail-bottom">
            <IconDelete className="cor-documentsDetail-bottom-del" onClick={handleDeleteClick} />
            <div className="cor-documentsDetail-bottom-date">
              Last published {moment(data.updatedAt).format('YYYY-MM-DD')}
            </div>
            <Button
              onClick={handleEdit}
              className="cor-documentsDetail-bottom-edit"
              icon={<IconEdit style={{ width: '21px', height: '24px' }} />}>
              Edit Entry
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentsDetail;
