import { IKnowledge } from '@/types/knowledge';
import { IKnowledgeMessage } from '@/types/messages';
import { IResponse } from '@/types/settings';
import { axiosGet, axiosPost } from '@/utils/axios';
import { AppState } from '@auth0/auth0-react';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

const apiRoot = (process.env.REACT_APP_API_ROOT_V2 || '') + 'knowledge';
export const setKnowledgeMessage = createAction<{ message: IKnowledgeMessage }>('knowledge/setKnowledgeMessage');

export const getKnowledgeConversations = createAsyncThunk(
  'knowledge/getKnowledgeConversations',
  async ({ sessionId }: { sessionId: string }, { getState }): Promise<IKnowledgeMessage[] | undefined> => {
    try {
      const {
        knowledge: { replying },
      } = getState() as AppState;

      if (replying) {
        return undefined;
      }
      const result = await axiosGet(`${apiRoot}/conversations`, {
        params: {
          sessionId,
        },
      });
      return _.tail(result.data.data);
    } catch (e) {
      return undefined;
    }
  },
);
// 添加knowledge_directions
export const addknowledgeDirections = createAsyncThunk(
  'knowledge/addknowledgeDirections',
  async ({ name }: { name: string }, { dispatch }): Promise<IResponse<IKnowledge[]>> => {
    try {
      const result = await axiosPost(`${process.env.REACT_APP_API_ROOT_V2}knowledge_directions`, { name });
      dispatch(getknowledgeDirections());
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
// 获取 knowledge_directions 列表
export const getknowledgeDirections = createAsyncThunk(
  'knowledge/getknowledgeDirections',
  async (): Promise<IResponse<IKnowledge[]>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}knowledge_directions`);
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
