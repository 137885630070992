/**
 * @description Component-UserList
 */
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Empty } from 'antd';
import _ from 'lodash';
import InfiniteScroller from 'react-infinite-scroller';

import { useMailSettings, useMailSettingsStatus, useOrg } from '@/features/settings/hooks';
import { useCurrentUserId, useInboxSearchStr, useUserList, useUserListStatus } from '@/features/inbox/hooks';
import { useAppDispatch } from '@/features/hooks';
import {
  getArchiveById,
  getUserList,
  GetUserListRequest,
  getUserMailList,
  setInboxCurrentId,
  putReadById,
} from '@/features/inbox/actions';
import { spliceUserListByFrom } from '@/features/inbox/slice';
import { FETCHING_STATUS, SETTINGS_TABS } from '@/constants';
import { IInboxUser } from '@/types/inbox';
import ListSkeleton from '@/pages/components/ListSkeleton';
import { useNavigate } from 'react-router-dom';
import ListItem from '@/components/ListItem';
import './index.less';
import { useDebounceEffect } from 'ahooks';

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}

const UserList: FC<IPropTypes> = function () {
  const intervalRef = useRef<number | null>(null);

  const org = useOrg();
  const users = useUserList(org?.id);
  const userStatus = useUserListStatus(org?.id);
  const dispatch = useAppDispatch();
  const query = useInboxSearchStr(org?.id);
  const currentId = useCurrentUserId(org?.id);
  const listRef = useRef<HTMLDivElement>(null);
  const firstUserRef = useRef<HTMLDivElement>(null);
  const prevScrollHeight = useRef<number>(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const mailSettingsStatus = useMailSettingsStatus(org?.id);
  const mailSettings = useMailSettings(org?.id);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      (userStatus === FETCHING_STATUS.DONE || userStatus === FETCHING_STATUS.COMPLETED) &&
      scrollContainerRef.current &&
      prevScrollHeight.current !== 0
    ) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight - prevScrollHeight.current;
    }
  }, [userStatus, prevScrollHeight, scrollContainerRef]);

  const selectUser = useCallback(
    (user: IInboxUser) => {
      org && dispatch(setInboxCurrentId({ orgId: org?.id, id: user.from }));
      org && dispatch(getUserMailList({ orgId: org?.id, from: user.from }));
      org && user?.unread && dispatch(putReadById({ orgId: org.id, id: user.id, from: user.from }));
    },
    [dispatch, org],
  );

  useEffect(() => {
    const id = currentId;
    if (id === _.head(users)?.id) {
      listRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (id === _.last(users)?.id) {
      listRef.current?.scrollTo({ top: listRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [currentId, users]);

  const onLoad = useCallback(
    async (mode?: string | number) => {
      if (
        org &&
        (userStatus === undefined ||
          userStatus === FETCHING_STATUS.DONE ||
          mode === 'unshift' ||
          (query !== undefined && userStatus < FETCHING_STATUS.FETCHING))
      ) {
        const params: GetUserListRequest = {
          orgId: org.id,
          mode: 'refresh',
        };
        if (mode === 'unshift') {
          params.mode = mode;
          // params.nextId = _.head(users)?.id;
        }
        if (_.last(users)?.id && (!mode || userStatus === FETCHING_STATUS.DONE)) {
          params.mode = 'append';
          params.preId = _.last(users)?.id;
        }
        if (mode === 'refresh' || !!query) {
          params.mode = 'refresh';
          params.query = query;
          params.preId = '';
        }
        dispatch(getUserList(params));
      }
    },
    [org, users, userStatus, dispatch, query],
  );

  useEffect(() => {
    const polling = setInterval(() => {
      if (userStatus !== FETCHING_STATUS.FETCHING) {
        console.log('🚀 ~ file: index.tsx:119 ~ polling ~ userStatus:', userStatus);
        onLoad('unshift');
      }
    }, 60 * 1000);

    return () => {
      if (polling) {
        clearInterval(polling);
        intervalRef.current = null;
      }
    };
  }, [onLoad, userStatus]);

  // useEffect(() => {
  //   startCount();
  // }, []);

  useEffect(() => {
    if (query !== undefined) {
      onLoad('refresh');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const onSplice = (e: React.MouseEvent<HTMLElement, MouseEvent>, user: IInboxUser) => {
    e.stopPropagation();
    if (org) {
      dispatch(getArchiveById({ orgId: org.id, id: user.id }));
      dispatch(spliceUserListByFrom({ orgId: org.id, from: user.from }));
      dispatch(setInboxCurrentId({ orgId: org?.id, id: '' }));
    }
  };

  useDebounceEffect(() => {
    if (firstUserRef.current && users?.length && (!currentId || !users.some((u) => u.from === currentId))) {
      firstUserRef.current.click();
    }
  }, [users, currentId]);

  return (
    <div className="cor-user-list-wrap" ref={scrollContainerRef}>
      <InfiniteScroller
        className="cor-user-list"
        initialLoad={true}
        useWindow={false}
        hasMore={userStatus !== FETCHING_STATUS.COMPLETED}
        getScrollParent={() => scrollContainerRef.current}
        loadMore={onLoad}>
        {userStatus === FETCHING_STATUS.FETCHING && !users?.length && <ListSkeleton />}
        {mailSettingsStatus === FETCHING_STATUS.DONE &&
          !!mailSettings.length &&
          (userStatus === FETCHING_STATUS.DONE || userStatus === FETCHING_STATUS.COMPLETED) &&
          !users?.length && (
            <Empty
              description={
                <>
                  <div>Please wait</div>
                  <div>Updating mails may take up to 3 mins...</div>
                </>
              }
            />
          )}
        {!mailSettings.length && mailSettingsStatus === FETCHING_STATUS.DONE && (
          <Empty
            description={
              <>
                <div>No mail connected!</div>
                <div>
                  Please set up mail connection first!{' '}
                  <a onClick={() => navigate(`/dashboard/settings/${SETTINGS_TABS.CONNECTIONS}`)}>Settings</a>
                </div>
              </>
            }
          />
        )}
        {users?.map((user, index) => (
          <ListItem
            ref={index === 0 ? firstUserRef : listRef}
            key={user.id}
            item={user}
            currentId={currentId}
            onSelect={selectUser}
            onArchive={onSplice}
          />
        ))}
      </InfiniteScroller>
    </div>
  );
};

export default UserList;
