/**
 * @description Component-PlaygroundReplyBox
 */
import React, { FC, useCallback, useState } from 'react';
import './index.less';
import { v4 as uuidv4 } from 'uuid';
import { setKnowledgeMessage } from '@/features/knowledge/actions';
import { useCurrentMerchant } from '@/features/demo/hooks';
import { useAppDispatch } from '@/features/hooks';
import { IMessageRole } from '@/types/aigc';
import { SendOutlined } from '@ant-design/icons';
import { Input, Button } from 'antd';
import _ from 'lodash';
// import { useKnowledgeReplying } from '@/features/knowledge/hooks';
import { useSocket } from '@/features/socket/hooks';
import { SOCKET_CHANNELS } from '@/constants/sockets';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const PlaygroundReplyBox: FC<IPropTypes> = function () {
  const dispatch = useAppDispatch();
  const [currentText, setCurrentText] = useState('');
  // const replying = useKnowledgeReplying();
  const socket = useSocket(SOCKET_CHANNELS.KNOWLEDGE_PLAYGROUND);
  const inputMessage = (messageText: string) => {
    setCurrentText(messageText);
  };
  const onInput = useCallback(() => {
    socket?.emit('message', {
      question: currentText,
    });
    dispatch(
      setKnowledgeMessage({
        message: {
          id: uuidv4(),
          content: currentText,
          role: IMessageRole.USER,
          createdAt: Date.now(),
        },
      }),
    );
    setCurrentText('');
  }, [currentText, dispatch, socket]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (currentText && !replying) {
        onInput();
      }
    }
  };

  return (
    <div className="cor-playground-reply-box">
      <div className="cor-playground-reply-box-input-container">
        <div className="textarea">
          <Input.TextArea
            disabled={!socket}
            onChange={(e) => inputMessage(e.target.value)}
            value={currentText}
            onKeyDown={(e) => handleKeyDown(e)}
            rows={1}
            placeholder="Ask here..."
            bordered
          />
          <Button onClick={onInput} disabled={!currentText || !socket}>
            <SendOutlined />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PlaygroundReplyBox;
