/**
 * @description Component-StatusSelect
 */
import React, { FC, useCallback, useState } from 'react';
import './index.less';
import { Select } from 'antd';
import { TICKET_STATUS } from '@/constants/Tickets';
import { ITicket } from '@/types/plugin';
import { useAppDispatch } from '@/features/hooks';
import { updateTicket } from '@/features/plugin/actions';
import { useOrg } from '@/features/settings/hooks';
import _ from 'lodash';
import classNames from 'classnames';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  ticket: ITicket;
}
const StatusSelect: FC<IPropTypes> = function ({ ticket }) {
  const org = useOrg();
  const dispatch = useAppDispatch();
  const [changing, setChanging] = useState(false);
  const changeStatus = useCallback(
    async (status: string) => {
      try {
        setChanging(true);
        if (org) {
          await dispatch(
            updateTicket({
              ..._.omit(ticket, ['assignees']),
              orgId: org.id,
              status,
            }),
          );
        }
        setChanging(false);
      } catch (e) {
        setChanging(false);
      }
    },
    [dispatch, org, ticket],
  );
  return (
    <Select
      loading={changing}
      onChange={changeStatus}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      value={ticket.status}
      popupClassName="cor-status-select-popup"
      className={classNames('cor-status-select', ticket.status)}
      placement="bottomRight">
      <Select.Option value={TICKET_STATUS.PENDING}>Pending</Select.Option>
      <Select.Option value={TICKET_STATUS.PROCESSING}>Processing</Select.Option>
      <Select.Option value={TICKET_STATUS.COMPLETED}>Completed</Select.Option>
    </Select>
  );
};

export default StatusSelect;
