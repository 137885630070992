/**
 * @description Component-SiderLeft
 */
import React, { FC } from 'react';
import './index.less';
import { ReactComponent as InboxIcon } from '@/pages/InboxMobile/assets/inbox_icon.svg';
import { ReactComponent as InsightIcon } from '@/pages/InboxMobile/assets/insight_icon.svg';
// import { ReactComponent as MailIcon } from '@/assets/svg/icon_list_mail.svg';
import { animated } from 'react-spring';
// import { ReactComponent as PluginIcon } from '@/pages/Dashboard/assets/icon_sidebar_plugin.svg';
import { ReactComponent as HomeIcon } from '@/pages/InboxMobile/assets/home_icon.svg';
import { useGetDashboardMenuItem } from '@/hooks';
import { DASHBOARD_MENU_ITEMS } from '@/constants';
import { Link } from 'react-router-dom';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const TabBar: FC<IPropTypes> = function ({}) {
  const activeKey = useGetDashboardMenuItem();
  return (
    <animated.div className="cor-tabbar">
      <Link
        to={'/dashboard/home'}
        className={`cor-tabbar-item ${activeKey === DASHBOARD_MENU_ITEMS.HOME ? 'active' : ''}`}>
        <HomeIcon className="icon" />
        <span className={`cor-tabbar-item-text `}>Home</span>
      </Link>
      <Link
        to={'/dashboard/inbox'}
        className={`cor-tabbar-item ${activeKey === DASHBOARD_MENU_ITEMS.INBOX ? 'active' : ''}`}>
        <InboxIcon className="icon" />
        <span className={`cor-tabbar-item-text`}>Inbox</span>
      </Link>
      <Link
        to={'/dashboard/meta'}
        className={`cor-tabbar-item ${activeKey === DASHBOARD_MENU_ITEMS.META ? 'active' : ''}`}>
        <InsightIcon className="icon" />
        <span className={`cor-tabbar-item-text`}>Meta</span>
      </Link>
      <Link
        to={'/dashboard/web-chat'}
        className={`cor-tabbar-item ${activeKey === DASHBOARD_MENU_ITEMS.WEB_CHAT ? 'active' : ''}`}>
        {/* <PluginIcon className="icon" /> */}
        <span className={`cor-tabbar-item-text`}>Web Chat</span>
      </Link>
    </animated.div>
  );
};

export default TabBar;
