import { createSlice } from '@reduxjs/toolkit';
import {
  createTicket,
  deleteTicket,
  getOrderCancelRequest,
  getPluginId,
  getSessionConversation,
  getSessions,
  getTickets,
  setSelectedSession,
  setTicketGetParam,
  updateTicket,
} from './actions';
import _ from 'lodash';
import { AppState } from '@auth0/auth0-react';
import { ListArrayStatus, setFulfilledEndListArrayStatus, setFulfilledListArrayStatus } from '@/types/redux';
import { IOrderCancelRequest, IPluginSession, ITicket } from '@/types/plugin';
import { ERROR_CODE, FETCHING_STATUS } from '@/constants';
import { TICKET_STATUS } from '@/constants/Tickets';
export enum TicketParamSort {
  ASC = 'asc',
  DESC = 'desc',
}
export enum TicketParamSortBy {
  TITLE = 'title',
  STATUS = 'status',
  ASSIGNEES = 'assignees',
  PRIORITY = 'priority',
  CREATEDAT = 'createdAt',
}

export interface ITicketGetParam {
  pageSize?: number;
  page?: number;
  query?: string;
  status?: TICKET_STATUS;
  sort?: TicketParamSort;
  sortBy?: TicketParamSortBy;
  assignees?: string[];
}

export interface IPluginState {
  orgPluginId: {
    [org: string]: string;
  };
  orgPluginSessions: { [org: string]: ListArrayStatus<IPluginSession> };
  orgTickets: { [org: string]: ListArrayStatus<ITicket> };
  sessionConversations: { [sessionId: string]: ListArrayStatus<IPluginSession> };
  orgSelectedSession: { [org: string]: IPluginSession };
  orgOrderCancelRequests: { [org: string]: ListArrayStatus<IOrderCancelRequest[]> };
  ticketSavingStatus: {
    [orgId: string]: FETCHING_STATUS;
  };
  ticketDeletingStatus: {
    [id: string]: FETCHING_STATUS;
  };
  ticketGetParam: ITicketGetParam;
  ticketListTotal: number;
}

const initialState: IPluginState = {
  orgPluginId: {},
  orgPluginSessions: {},
  orgSelectedSession: {},
  sessionConversations: {},
  orgOrderCancelRequests: {},
  orgTickets: {},
  ticketSavingStatus: {},
  ticketDeletingStatus: {},
  ticketGetParam: {
    pageSize: 10, //REQUEST_PAGE_SIZE
    page: 1,
    sort: TicketParamSort.DESC,
    sortBy: TicketParamSortBy.CREATEDAT,
    query: '',
    status: TICKET_STATUS.ALL,
    assignees: [],
  },
  ticketListTotal: 0,
};

export const pluginSlice = createSlice({
  name: 'plugin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPluginId.fulfilled, (state, { meta, payload }) => {
        if (payload.data) {
          _.set(state.orgPluginId, [meta.arg.orgId], payload.data);
        }
      })
      .addCase(getPluginId.rejected, (state, { meta }) => {
        console.log('🚀 ~ .addCase ~ meta:', meta);
      });
    builder.addCase(getSessions.pending, (state, { meta }) => {
      const { search } = meta.arg;
      const sessions = _.get(state.orgPluginSessions, [meta.arg.orgId, 'list'], []);
      if (!sessions.length || !!search) {
        _.set(state.orgPluginSessions, [meta.arg.orgId, 'status'], FETCHING_STATUS.FETCHING);
      }
    });
    builder.addCase(getSessions.fulfilled, (state, { meta, payload }) => {
      const { nextId, search } = meta.arg;
      if (payload.errno === ERROR_CODE.NORMAL) {
        if (!!search) {
          _.set(state.orgPluginSessions, [meta.arg.orgId], setFulfilledEndListArrayStatus(payload.data));
        } else {
          const currentList = _.get(state.orgPluginSessions, [meta.arg.orgId, 'list'], []);
          const sessions = nextId ? [...payload.data, ...currentList] : [...currentList, ...payload.data];
          _.set(
            state.orgPluginSessions,
            [meta.arg.orgId],
            !payload.data.length || (meta.arg.pageSize && payload.data.length < meta.arg.pageSize)
              ? setFulfilledEndListArrayStatus(sessions)
              : setFulfilledListArrayStatus(sessions),
          );
        }
      }
    });
    builder.addCase(getSessions.rejected, (state, { meta }) => {
      _.set(state.orgPluginSessions, [meta.arg.orgId, 'status'], FETCHING_STATUS.DONE);
    });
    builder.addCase(getSessionConversation.pending, (state, { meta }) => {
      _.set(state.sessionConversations, [meta.arg.conversationId, 'status'], FETCHING_STATUS.FETCHING);
    });
    builder.addCase(getSessionConversation.fulfilled, (state, { meta, payload }) => {
      if (payload.errno === ERROR_CODE.NORMAL) {
        _.set(state.sessionConversations, [meta.arg.conversationId], setFulfilledListArrayStatus(payload.data));
      }
    });
    builder.addCase(getSessionConversation.rejected, (state, { meta }) => {
      _.set(state.sessionConversations, [meta.arg.conversationId, 'status'], FETCHING_STATUS.DONE);
    });
    builder.addCase(setSelectedSession, (state, { payload }) => {
      _.set(state.orgSelectedSession, [payload.orgId], payload.session);
    });
    builder.addCase(getOrderCancelRequest.pending, (state, { meta }) => {
      _.set(state.orgOrderCancelRequests, [meta.arg.orgId, 'status'], FETCHING_STATUS.FETCHING);
    });
    builder.addCase(getOrderCancelRequest.fulfilled, (state, { meta, payload }) => {
      if (payload) {
        const currentList = _.get(state.orgOrderCancelRequests, [meta.arg.orgId, 'list']);
        const newList = meta.arg.nextId ? [...currentList, ...payload] : payload;
        _.set(
          state.orgOrderCancelRequests,
          [meta.arg.orgId],
          payload.length < meta.arg.pageSize
            ? setFulfilledEndListArrayStatus(newList)
            : setFulfilledListArrayStatus(newList),
        );
      }
    });
    builder.addCase(getOrderCancelRequest.rejected, (state, { meta }) => {
      _.set(state.orgOrderCancelRequests, [meta.arg.orgId, 'status'], FETCHING_STATUS.DONE);
    });
    builder
      .addCase(getTickets.pending, (state, { meta }) => {
        _.set(state.orgTickets, [meta.arg.orgId, 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getTickets.fulfilled, (state, { meta, payload }) => {
        const data = payload?.data;
        state.ticketListTotal = payload?.meta?.total || 0;
        if (data) {
          _.set(state.orgTickets, [meta.arg.orgId], setFulfilledListArrayStatus(data));
        } else {
          _.set(state.orgTickets, [meta.arg.orgId], setFulfilledListArrayStatus([]));
          // _.set(state.orgTickets, [meta.arg.orgId, 'status'], FETCHING_STATUS.DONE);
        }
      })
      .addCase(getTickets.rejected, (state, { meta }) => {
        _.set(state.orgTickets, [meta.arg.orgId, 'status'], FETCHING_STATUS.DONE);
      })
      .addCase(deleteTicket.pending, (state, { meta }) => {
        _.set(state.ticketDeletingStatus, [meta.arg.id], FETCHING_STATUS.FETCHING);
      })
      .addCase(deleteTicket.fulfilled, (state, { meta }) => {
        _.set(state.ticketDeletingStatus, [meta.arg.id], FETCHING_STATUS.DONE);
      })
      .addCase(createTicket.pending, (state, { meta }) => {
        _.set(state.ticketSavingStatus, [meta.arg.orgId], FETCHING_STATUS.FETCHING);
      })
      .addCase(createTicket.fulfilled, (state, { meta }) => {
        _.set(state.ticketSavingStatus, [meta.arg.orgId], FETCHING_STATUS.DONE);
      })
      .addCase(updateTicket.pending, (state, { meta }) => {
        _.set(state.ticketSavingStatus, [meta.arg.orgId], FETCHING_STATUS.FETCHING);
      })
      .addCase(updateTicket.fulfilled, (state, { meta }) => {
        _.set(state.ticketSavingStatus, [meta.arg.orgId], FETCHING_STATUS.DONE);
      });
    builder.addCase(setTicketGetParam, (state, { payload }) => {
      state.ticketGetParam = { ...state.ticketGetParam, ...payload };
    });
  },
});

export const selectPluginId =
  (orgId: string | undefined) =>
    (state: AppState): string | undefined =>
      _.get(state.plugin.orgPluginId, [orgId || '']);
export const selectTickets =
  (orgId: string | undefined) =>
    (state: AppState): ITicket[] | undefined =>
      _.get(state.plugin.orgTickets, [orgId || '', 'list']);
export const selectTicketsStatus =
  (orgId: string | undefined) =>
    (state: AppState): FETCHING_STATUS | undefined =>
      _.get(state.plugin.orgTickets, [orgId || '', 'status']);
export const selectSavingTicketStatus =
  (orgId: string | undefined) =>
    (state: AppState): FETCHING_STATUS | undefined =>
      _.get(state.plugin.ticketSavingStatus, [orgId || '']);
export const selectDeletingTicketStatus =
  (id: string | undefined) =>
    (state: AppState): FETCHING_STATUS | undefined =>
      _.get(state.plugin.ticketDeletingStatus, [id || '']);

export const selectTicketGetParam =
  () =>
    (state: AppState): ITicketGetParam =>
      state.plugin.ticketGetParam;

export const selectPluginSessions =
  (orgId: string | undefined) =>
    (state: AppState): IPluginSession[] | undefined =>
      _.get(state.plugin.orgPluginSessions, [orgId || '', 'list']);
export const selectSessionsConversations =
  (conversationId: string | undefined) =>
    (state: AppState): IPluginSession[] | undefined =>
      _.get(state.plugin.sessionConversations, [conversationId || '', 'list']);
export const selectSessionsConversationsStatus =
  (conversationId: string | undefined) =>
    (state: AppState): FETCHING_STATUS | undefined =>
      _.get(state.plugin.sessionConversations, [conversationId || '', 'status']);
export const selectOrderCancelRequests =
  (orgId: string | undefined) =>
    (state: AppState): IOrderCancelRequest[] | undefined =>
      _.get(state.plugin.orgOrderCancelRequests, [orgId || '', 'list']);
export const selectOrderCancelRequestsStatus =
  (orderId: string | undefined) =>
    (state: AppState): FETCHING_STATUS | undefined =>
      _.get(state.plugin.orgOrderCancelRequests, [orderId || '', 'status']);
export const selectSelectedSession =
  (orgId: string | undefined) =>
    (state: AppState): IPluginSession | undefined =>
      _.get(state.plugin.orgSelectedSession, [orgId || '']);

export const selectPluginSessionsStatus =
  (orgId: string | undefined) =>
    (state: AppState): FETCHING_STATUS | undefined =>
      _.get(state.plugin.orgPluginSessions, [orgId || '', 'status']);

export const selectTicketListTotal =
  () =>
    (state: AppState): number =>
      state.plugin.ticketListTotal;

export default pluginSlice.reducer;
