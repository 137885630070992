import React, { FC, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './index.less';
import { KNOWLEDGE_TABS } from '@/constants';
import Documents from './Documents';
import Playground from './Playground';
import { ReactComponent as AddIcon } from '@/assets/svg/icon_add_16.svg';
import UploadKnowledgeFile from './Documents/DocumentsDetail/UploadKnowledgeFile';
import { Button } from 'antd';
import AddUrl from './Documents/DocumentsDetail/AddUrl';
interface IPropTypes {
  className?: string;
}

const Knowledge: FC<IPropTypes> = function () {
  const loc = useLocation();
  const [selectedTab, setSelectedTab] = useState(loc.state?.tab || KNOWLEDGE_TABS.DOCUMENTS);
  const childRef = useRef<any>(null);

  const handleEditchange = () => {
    console.log('childRef');
    if (childRef.current) {
      childRef.current.handleEditChange(true, '');
    }
  };
  const items = useMemo(
    () => [
      { key: KNOWLEDGE_TABS.DOCUMENTS, label: 'Documents' },
      { key: KNOWLEDGE_TABS.WEBSITES, label: 'Websites' },
      { key: KNOWLEDGE_TABS.PLAYGROUND, label: 'Playground' },
    ],
    [],
  );

  return (
    <div className="cor-knowledge">
      <div className="cor-knowledge-head">
        <div className="cor-knowledge-head-title">Knowledge Base</div>
        <div className="cor-knowledge-head-tablist">
          {items.map((item) => (
            <div
              key={item.key}
              className={`cor-knowledge-head-tablist-item ${
                selectedTab === item.key ? 'cor-knowledge-head-tablist-active' : ''
              }`}
              onClick={() => setSelectedTab(item.key)}>
              {item.label}
            </div>
          ))}
        </div>
        {selectedTab === KNOWLEDGE_TABS.DOCUMENTS && (
          <div className="cor-knowledge-head-btn">
            <Button
              className="cor-knowledge-head-btn-enbtn"
              icon={<AddIcon />}
              type="primary"
              onClick={handleEditchange}>
              Add Entry
            </Button>
            <UploadKnowledgeFile />
          </div>
        )}
        {selectedTab === KNOWLEDGE_TABS.WEBSITES && (
          <div className="cor-knowledge-head-btn">
            <AddUrl />
          </div>
        )}
      </div>

      <div className="cor-knowledge-content">
        {selectedTab === KNOWLEDGE_TABS.DOCUMENTS && <Documents ref={childRef} tabs={'documents'} />}
        {selectedTab === KNOWLEDGE_TABS.WEBSITES && <Documents ref={childRef} tabs={'websites'} />}
        {selectedTab === KNOWLEDGE_TABS.PLAYGROUND && <Playground />}
      </div>
    </div>
  );
};

export default Knowledge;
