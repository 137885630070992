import { axiosGet, axiosPost, axiosPut, axiosDelete } from '@/utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IResponse } from '@/types/settings';
import { IComment, IAttachment } from '@/types/comment';

// 添加评论的 action
export const addComment = createAsyncThunk(
  'ticket/addComment',
  async (
    {
      ticketId,
      author,
      authorName,
      content,
      attachments,
    }: { ticketId: string; author: string; authorName: string; content: string; attachments: IAttachment[] },
    { dispatch },
  ): Promise<IResponse<IComment>> => {
    try {
      const result = await axiosPost(`${process.env.REACT_APP_API_ROOT_V2}ticket/comments`, {
        ticketId,
        author,
        authorName,
        content,
        attachments,
      });
      dispatch(getComments({ ticketId }));
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
// 更新评论 action
export const updateComment = createAsyncThunk(
  'ticket/updateComment',
  async (
    {
      ticketId,
      id,
      content,
      attachments,
    }: { ticketId: string; id: string; content: string; attachments: IAttachment[] },
    { dispatch },
  ): Promise<IResponse<IComment>> => {
    try {
      const result = await axiosPut(`${process.env.REACT_APP_API_ROOT_V2}ticket/comments`, {
        id,
        content,
        attachments,
      });
      dispatch(getComments({ ticketId }));
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
// 获取评论的 action
export const getComments = createAsyncThunk(
  'ticket/getComments',
  async ({ ticketId }: { ticketId: string }): Promise<IResponse<IComment[]>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}ticket/comments`, { params: { ticketId } });
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);

export const delComment = createAsyncThunk(
  'ticket/delComment',
  async ({ ticketId, id }: { ticketId: string; id: string }, { dispatch }): Promise<IResponse<IComment>> => {
    try {
      const result = await axiosDelete(`${process.env.REACT_APP_API_ROOT_V2}ticket/comments`, {
        id,
      });
      dispatch(getComments({ ticketId }));
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
