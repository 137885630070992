/**
 * @description Component-ConversationItem
 */
import React, { FC, useMemo } from 'react';
import './index.less';
import { IConversationContent, IPluginSession } from '@/types/plugin';
import classNames from 'classnames';
import stoneIcon from '@/assets/svg/stone.svg';
import personIcon from '@/assets/svg/person.svg';
import MDEditor from '@uiw/react-md-editor';
import {
  END_OF_FUNCTIONS,
  END_OF_PLAINTEXT,
  IMessageRole,
  START_OF_FUNCTIONS,
  START_OF_PAYLOAD,
} from '@/constants/plugin';
import Avatar from 'react-avatar';
import dayjs from 'dayjs';
import { DATE_FORMAT_DETAIL } from '@/constants';
import LinkPreviewer from '../SessionList/LinkPreviewer';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import _ from 'lodash';

interface IPropTypes {
  conversation?: IPluginSession;
  className?: string;
}
const ConversationItem: FC<IPropTypes> = function ({ conversation }) {
  const { deviceType } = useMediaQueryDevice();
  const parsedItem = useMemo(() => {
    if (conversation?.role === IMessageRole.ASSISTANT) {
      try {
        return JSON.parse(conversation?.content) as IConversationContent;
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  }, [conversation?.content, conversation?.role]);
  const content = useMemo(() => {
    if (conversation?.role !== IMessageRole.ASSISTANT) {
      return conversation?.content.split(START_OF_PAYLOAD)[0];
    }
    return parsedItem
      ? parsedItem.content
      : conversation?.content.split(END_OF_PLAINTEXT)[0]?.split(START_OF_FUNCTIONS)[0];
  }, [conversation?.content, conversation?.role, parsedItem]);

  const recommendation = useMemo(() => {
    if (conversation?.role !== IMessageRole.ASSISTANT) {
      return undefined;
    }
    if (conversation?.tool) {
      const rec = _.find(conversation?.tool, (f) => f.name === 'redirect');
      return rec;
    }
    const recommendationStr = conversation?.content
      .split(START_OF_FUNCTIONS)[1]
      ?.replace(START_OF_FUNCTIONS, '')
      .replace(END_OF_FUNCTIONS, '');
    if (recommendationStr) {
      try {
        const rec = JSON.parse(recommendationStr);
        return rec;
      } catch (e) {
        console.log('🚀 ~ recommendation ~ e:', e);
      }
    }
  }, [conversation?.content, conversation?.role, conversation?.tool]);
  return (
    <>
      <div className={classNames('cor-conversation-item', conversation?.role, deviceType)}>
        <div className="cor-conversation-item-top">
          <Avatar
            className="ant-avatar"
            round
            size="45"
            name={conversation?.role === IMessageRole.ASSISTANT ? 'Cornerstone' : conversation?.user}
          />
          <div className="cor-conversation-item-content">
            <div className="cor-conversation-item-content-top">
              <div className="cor-conversation-item-timestamp">
                <div className="cor-conversation-item-timestamp-name">
                  {conversation?.role === IMessageRole.CUSTOMER ? conversation?.user : ''}
                </div>
                <div className="date-and-option">
                  <span className="date">{dayjs(conversation?.createdAt).format(DATE_FORMAT_DETAIL)}</span>
                </div>
              </div>

              {content && <MDEditor.Markdown className="cor-conversation-item-text" source={content} />}
            </div>
          </div>
        </div>
        <LinkPreviewer
          url={
            recommendation?.params?.productUrl ||
            (recommendation?.params?.url?.includes('www.youtube.com') ? '' : recommendation?.params?.url)
          }
        />
      </div>
    </>
  );
};

export default ConversationItem;
