/**
 * @description Component-PlaygroundChat
 */
import React, { FC, useCallback, useEffect, useRef } from 'react';
import './index.less';
import PlaygroundReplyBox from '../PlaygroundReplyBox';
import { useSocket } from '@/features/socket/hooks';
import { SOCKET_CHANNELS } from '@/constants/sockets';
import { useAuth0 } from '@auth0/auth0-react';
import { IDemoMessage } from '@/types/demo';
import classNames from 'classnames';
import _ from 'lodash';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import { useDebounceFn } from 'ahooks';
import { useAppDispatch } from '@/features/hooks';
import { setKnowledgeMessage } from '@/features/knowledgePlay/actions';
import { useKnowledgeMessages } from '@/features/knowledgePlay/hooks';
import PlaygroundChatItem from './PlaygroundChatItem';
import { IMessageRole } from '@/types/aigc';
import { v4 as uuidv4 } from 'uuid';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const PlaygroundChat: FC<IPropTypes> = function () {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { deviceType } = useMediaQueryDevice();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const socket = useSocket(SOCKET_CHANNELS.KNOWLEDGE_PLAYGROUND);
  const messages = useKnowledgeMessages();
  const { run: scrollBottom } = useDebounceFn(
    () => {
      containerRef.current?.scrollTo({ top: containerRef.current.scrollHeight, behavior: 'smooth' });
    },
    { wait: 10 },
  );
  const onInputMessageChange = useCallback(
    (question: string) => {
      socket?.emit('message', {
        question,
      });
      dispatch(
        setKnowledgeMessage({
          message: {
            id: uuidv4(),
            content: question,
            role: IMessageRole.USER,
            sessionId: '',
            createdAt: Date.now(),
          },
        }),
      );
    },
    [dispatch, socket],
  );
  const onResponse = useCallback(
    async (message: IDemoMessage) => {
      if (message.errorCode === 403) {
        await getAccessTokenSilently();
        return;
      }
      message.sessionId &&
        dispatch(
          setKnowledgeMessage({
            message,
          }),
        );
      scrollBottom();
    },
    [dispatch, getAccessTokenSilently, scrollBottom],
  );
  useEffect(() => {
    if (socket) {
      socket.on('response', onResponse);
    }
    return () => {
      socket && socket.off('response', onResponse);
    };
  }, [onResponse, socket]);
  return (
    <div className="cor-playground-chat">
      <div className="cor-playground-chat-header">Test Knowledge Integration</div>
      <div ref={containerRef} className={classNames('cor-merchant-detail', deviceType)}>
        {/* {messagesStatus === FETCHING_STATUS.FETCHING && !messages?.length && <SessionDetailSkeleton />} */}
        {messages?.map((item) => (
          <PlaygroundChatItem key={item.id} message={item} onInputMessageChange={onInputMessageChange} />
        ))}
      </div>
      <PlaygroundReplyBox />
    </div>
  );
};

export default PlaygroundChat;
