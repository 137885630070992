/**
 * @description Component-DocumentsDetail
 */
import React, { FC, useEffect, useState } from 'react';
import './index.less';
import { ReactComponent as IconDelete } from '@/assets/svg/icon_delete.svg';
import { ReactComponent as IconArrowback } from '@/assets/svg/arrow_back_ios.svg';
import { ReactComponent as IconEditoff } from '@/assets/svg/edit_off.svg';
import { DatePicker, Checkbox, Input, Tag, Tooltip, Modal, Form } from 'antd';
import { Button } from 'antd';
import moment from 'moment';
import MDEditor from '@uiw/react-md-editor';
import { PlusOutlined } from '@ant-design/icons';
import {
  getknowledgebaseDetail,
  updateknowledgebase,
  addknowledgebase,
  deleteknowledgebase,
} from '@/features/knowledge/actions';
import { useAppDispatch } from '@/features/hooks';
import { CloseOutlined } from '@ant-design/icons';
interface IPropTypes {
  className?: string;
  knowledgeId: string;
  status: string;
  tabs:string;
  onEditChange: (val: boolean, id: string) => void;
}
const DocumentsEdit: FC<IPropTypes> = function ({ knowledgeId, onEditChange, status ,tabs}) {
  const dispatch = useAppDispatch();
  const [content, setValue] = useState(``);
  const [activeStartDate, setStartDate] = useState<string | null>(null);
  const [activeEndDate, setEndDate] = useState<string | null>(null);
  const [startTime, setStartTime] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);
  const [title, setTitle] = useState('');
  const [activeInfinity, setActiveIndefinitely] = useState(false); // 复选框状态
  // 初始的Tags数组
  const [tags, setTags] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState(false); // 控制输入框显示与隐藏
  const [inputValue, setInputValue] = useState('');
  const [category, setCategory] = useState('document');
  const [saveloading, steSaveloading] = useState(false);
  const [publishloading, setPublishloading] = useState(false); 
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    console.log('knowledgeId:', knowledgeId);
    const fetchKnowledgeDetail = async () => {
      if (knowledgeId) {
        const result = await dispatch(getknowledgebaseDetail({ knowledge_id: knowledgeId }));
        console.log('result:', result);
        if (result.payload) {
          const { title, content, activeStartDate, activeEndDate, activeInfinity } = result.payload.data;
          if (result.payload.data.tags && result.payload.data.tags.length > 0) {
            const vTags: string[] = [];
            result.payload.data.tags.map((item: { name: string }) => {
              vTags.push(item.name);
            });
            console.log(vTags);
            setTags(vTags || []);
          }
          if(result.payload.data.category){
            setCategory(result.payload.data.category)
          }
          setTitle(title);
          setValue(content);
          setStartDate(activeStartDate);
          setEndDate(activeEndDate);
          setActiveIndefinitely(activeInfinity);
          form.setFieldsValue({
            title,
            content,
          });
          
        }
      }
    };
    fetchKnowledgeDetail();
  }, [dispatch, knowledgeId]);

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
    }
    setInputValue('');
    setInputVisible(false);
  };

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };
  const handleCheckboxChange = (e: any) => {
    setActiveIndefinitely(e.target.checked);
  };
  const handleSaveChange = async (status: string) => {
    try {
      await form.validateFields();
      
      const payload = {
        activeEndDate,
        activeInfinity,
        activeStartDate,
        content,
        category: category,
        tags:tags,
        status,
        title,
      };

      if (knowledgeId) {
        steSaveloading(true);
        await dispatch(updateknowledgebase({ knowledgeId, ...payload }));
      } else {
        setPublishloading(true);
        await dispatch(addknowledgebase(payload));
      }

      onEditChange(false, knowledgeId || '');
    } catch (error) {
      console.error('Save changes failed:', error);
    } finally {
      steSaveloading(false);
      setPublishloading(false);
    }
  };

  const handleEntrychange = () => {
    const id = knowledgeId ? knowledgeId : '';
    onEditChange(false, id);
  };
  const handleDeleteClick = () => {
    Modal.confirm({
      title: 'Confirm Deletion',
      content: 'Are you sure you want to delete the current knowledge block?',
      onOk() {
        dispatch(deleteknowledgebase({ knowledgeId }));
        const id = knowledgeId ? knowledgeId : '';
        onEditChange(false, id);
      },
      okText: 'Yes',
      cancelText: 'No',
      className: 'delete-confirm-modal',
    });
  };
  const handlegoBack = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    const id = knowledgeId ? knowledgeId : '';
    onEditChange(false, id);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dateFormat = 'MM/DD/YYYY';
  const timeFormat = 'hh:mm A';

  return (
    <div className="cor-documentsEdit">
      <Form form={form} layout="vertical" style={{width:'100%'}}>
        <div className="cor-documentsEdit-head" onClick={handlegoBack}>
          {' '}
          <IconArrowback /> Back to Knowledge Entrie
        </div>
        <div className="cor-documentsEdit-center">
          <div className="cor-documentsEdit-center-title1">EDIT ENTRY</div>
          <Form.Item
            name="title"
            label="Knowledge Entry Title"
            rules={[{ required: true, message: 'Please enter a title' }]}
            initialValue={title}
          >
            <Input 
              value={title} 
              onChange={(e) => setTitle(e.target.value)} 
              placeholder="Knowledge Entry Title here" 
            />
          </Form.Item>
          <Form.Item
            name="content"
            label="Description"
            rules={[{ required: true, message: 'Please enter a description' }]}
            initialValue={content}
          >
            <MDEditor
              className="cor-documentsEdit-center-message"
              value={content}
              onChange={(content) => setValue(content || '')}
              preview="edit"
            />
          </Form.Item>
          <div className="date-time-picker-container">
            <div className="date-time-picker-item">
              <label>Start date:</label>
              <DatePicker
                format={dateFormat}
                value={activeStartDate ? moment(activeStartDate) : null}
                onChange={(date) => setStartDate(date)}
                placeholder="MM/DD/YYYY"
                disabled={activeInfinity}
              />
              {/* <TimePicker
                format={timeFormat}
                value={startTime ? moment(startTime) : null}
                onChange={(time) => setStartTime(time)}
                placeholder="00:00 AM"
                use12Hours
              /> */}
            </div>

            <div className="date-time-picker-item">
              <label>End date:</label>
              <DatePicker
                format={dateFormat}
                value={activeEndDate ? moment(activeEndDate) : null}
                onChange={(date) => setEndDate(date)}
                placeholder="MM/DD/YYYY"
                disabled={activeInfinity}
              />
              {/* <TimePicker
                format={timeFormat}
                value={endTime ? moment(endTime) : null}
                onChange={(time) => setEndTime(time)}
                placeholder="00:00 AM"
                use12Hours
              /> */}
            </div>
            <div className="checkbox-item">
              <Checkbox onChange={handleCheckboxChange} checked={activeInfinity}>
                Active Indefinitely
              </Checkbox>
            </div>
            <div className="cor-documentsEdit-center-xian"></div>
          </div>
        </div>
        <div className="cor-documentsEdit-center-tags">
          <div className="cor-documentsEdit-center-tags-left">Tags:</div>

          <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', alignItems: 'center' }}>
            {tags.map((tag, index) => {
              const isLongTag = tag.length > 20;
              const tagElem = (
                <Tag
                  key={tag}
                  closable={index !== -1} // 第一个不可以被关闭
                  onClose={() => handleClose(tag)}>
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
            {inputVisible ? (
              <Input
                type="text"
                size="small"
                style={{ width: 100 }}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm} // 输入框失焦时确认
                onPressEnter={handleInputConfirm} // 回车时确认
              />
            ) : (
              <Tag onClick={showInput} className="site-tag-plus">
                <PlusOutlined />
              </Tag>
            )}
          </div>
        </div>
      </Form>
      <div className="cor-documentsEdit-bottom">
        {knowledgeId && (
          <div>
            <IconDelete className="cor-documentsEdit-bottom-del" onClick={handleDeleteClick} />
          </div>
        )}
        <Button onClick={handleEntrychange} className="cor-documentsEdit-bottom-edit2">
          Cancel Changes
        </Button>
        <Button onClick={() => handleSaveChange('drafts')} className="cor-documentsEdit-bottom-edit" loading={saveloading}>
          Save Changes
        </Button>
        <Button
          onClick={() => handleSaveChange('published')}
          className="cor-documentsEdit-bottom-edit3"
          loading={publishloading}>
          Publish Entry
        </Button>
      </div>
      <Modal
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <IconEditoff />
            <span>Quit editing?</span>
          </div>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes, cancel changes"
        cancelText="No, continue editing"
        className="editing-confirm-modal"
        closeIcon={<CloseOutlined />}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span>All changes will be lost if you return to the knowledge entries without saving.</span>
        </div>
      </Modal>
    </div>
  );
};

export default DocumentsEdit;
