/**
 * @description Component-SendMailModal
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import './index.less';
import { ReactComponent as ComposeIcon } from '@/assets/svg/pen.svg';
import { ReactComponent as SendOutlined } from '@/assets/svg/icon_btnsend.svg';
import { ReactComponent as CloseIcon } from '@/assets/svg/icon_close_24.svg';
import { Button, Form, Input, Modal, Tooltip } from 'antd';
import { useAppDispatch } from '@/features/hooks';
import { sendDirectMail } from '@/features/inbox/actions';
import { useForm } from 'antd/lib/form/Form';
import { useOrg, useSignature } from '@/features/settings/hooks';
import { useSendingMail } from '@/features/inbox/hooks';
import { useMediaQueryDevice } from '../MediaQuery';
import classNames from 'classnames';
import { ReactComponent as ReplyIcon } from '@/assets/svg/reply_icon.svg';
import { MAIL_BTN_TYPE } from '@/constants/inbox';
import { useSocket } from '@/features/socket/hooks';
import { SOCKET_CHANNELS, SOCKET_STATUS } from '@/constants/sockets';
interface IPropTypes {
  to?: string | undefined;
  className?: string;
  toRequired?: boolean;
  type?: MAIL_BTN_TYPE;
  conversationId?: string | undefined;
}
const SendMailModal: FC<IPropTypes> = function ({ to, toRequired, conversationId, type = MAIL_BTN_TYPE.REPLY }) {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const org = useOrg();
  const { deviceType, isMobile } = useMediaQueryDevice();
  const [signatures] = useSignature(org?.id);
  const [formValid, setFormValid] = useState(false);
  const [toValue, setToValue] = useState(to);
  const [writeWithAi, setWriteWitAi] = useState(false);
  const [instruction, setInstruction] = useState('');
  const [generating, setGenerating] = useState(false);
  const sendingEmail = useSendingMail(toValue);
  const [modalOpen, setModalOpen] = useState(false);
  const socket = useSocket(SOCKET_CHANNELS.LIVE_CHAT_GENERATE_EMAIL);
  const [isFocused, setIsFocused] = useState(false);
  const sendEmail = useCallback(
    async ({ to, subject, body, signature }: { to: string; subject: string; body: string; signature: string }) => {
      if (org) {
        try {
          const result = await dispatch(
            sendDirectMail({
              to,
              subject,
              body: body + `\n${signature}`,
              orgId: org.id,
            }),
          ).unwrap();
          if (result.data) {
            setModalOpen(false);
          }
        } catch (e) {}
      }
    },

    [dispatch, org],
  );
  const onInputMessageChange = useCallback(
    (prompt: string) => {
      if (conversationId) {
        if (socket) {
          if (!socket.connected) {
            socket.connect();
            form.setFieldValue('body', '');
          }
          socket.emit('message', {
            conversationId,
            prompt,
          });
          setGenerating(true);
        }
      }
    },
    [form, conversationId, socket],
  );
  const onResponse = useCallback(
    (response: { sessionId: string; content: string; status: SOCKET_STATUS; error: string }) => {
      if (response.error) {
        console.log('🚀 ~ error:', response.error);
        setGenerating(false);
        return;
      }
      if (response.status !== SOCKET_STATUS.FINISHED) {
        form.setFieldValue('body', (form.getFieldValue('body') || '') + (response.content || ''));
      } else {
        setGenerating(false);
      }
    },
    [form],
  );
  useEffect(() => {
    if (socket) {
      socket.on('response', onResponse);
    }
    return () => {
      socket && socket.off('response', onResponse);
    };
  }, [dispatch, onResponse, socket]);
  if (toRequired && !to) {
    return null;
  }

  return (
    <>
      <div
        className="cor-send-mail-modal-container"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {to && !isMobile ? (
          <Tooltip title={to ? `Reply ${to}` : ''}>
            <Button
              className={classNames('cor-send-mail-modal-btn', deviceType)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setModalOpen(true);
              }}
              icon={<ReplyIcon />}
              type={'text'}
            />
          </Tooltip>
        ) : (
          <Button
            className={classNames('cor-send-mail-modal-btn', deviceType)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setModalOpen(true);
            }}
            icon={type === MAIL_BTN_TYPE.REPLY ? <ReplyIcon /> : <ComposeIcon />}
            type={to ? 'text' : 'primary'}>
            {to ? '' : isMobile ? '' : 'Compose'}
          </Button>
        )}

        <Modal
          centered={true}
          footer={false}
          title="Compose email"
          open={modalOpen}
          onCancel={() => {
            setModalOpen(false);
          }}
          className={classNames('cor-send-mail-modal', deviceType)}>
          <Form
            form={form}
            onValuesChange={async () => {
              try {
                const valid = await form.validateFields();
                console.log('🚀 ~ onValuesChange={ ~ valid:', valid);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (e: any) {
                setFormValid(!e?.errorFields?.length);
              }
            }}
            className="cor-send-mail-modal-form"
            initialValues={{ to, signature: signatures?.signature }}
            onFinish={(values) => {
              sendEmail(values);
            }}>
            <Form.Item
              name="to"
              label="To:"
              rules={[
                { required: true, message: 'Please enter an email' },
                { type: 'email', message: 'Please enter a valid email' },
              ]}>
              <Input onChange={(e) => setToValue(e.target.value)} />
            </Form.Item>
            <Form.Item label="Subject" rules={[{ required: true, message: 'Please enter a subject' }]} name="subject">
              <Input />
            </Form.Item>

            <div className={`cor-send-mail-modal-form-content ${isFocused ? 'focused' : ''}`}>
              {conversationId && (
                <div className="cor-send-mail-modal-form-content-ai-container">
                  {writeWithAi ? (
                    <>
                      <CloseIcon
                        onClick={() => setWriteWitAi(false)}
                        className="cor-send-mail-modal-form-content-ai-container-close"
                      />
                      <div className="cor-send-mail-modal-form-content-ai-container-label">Instruction:</div>
                      <div className="cor-send-mail-modal-form-content-ai-container-input">
                        <Input.TextArea
                          placeholder="e.g. Apologize to the customer, and give him a coupon code"
                          rows={2}
                          onChange={(e) => setInstruction(e.target.value)}
                          bordered
                        />
                        <Button type="primary" loading={generating} onClick={() => onInputMessageChange(instruction)}>
                          {' '}
                          Write
                        </Button>
                      </div>
                    </>
                  ) : (
                    <Button onClick={() => setWriteWitAi(true)}> Write With AI</Button>
                  )}
                </div>
              )}
              <Form.Item rules={[{ required: true, message: '' }]} name="body">
                <Input.TextArea
                  className="cor-send-mail-modal-form-content-content"
                  autoSize={{ minRows: 7, maxRows: 7 }}
                  bordered
                  onFocus={() => setIsFocused(true)}
                  onBlur={(e) => {
                    const relatedTarget = e.relatedTarget as HTMLElement;
                    if (!relatedTarget?.classList.contains('cor-send-mail-modal-form-content-signature')) {
                      setIsFocused(false);
                    }
                  }}
                />
              </Form.Item>

              <Form.Item name="signature">
                <Input.TextArea
                  className="cor-send-mail-modal-form-content-signature"
                  autoSize={{ minRows: 2, maxRows: 2 }}
                  bordered
                  onFocus={() => setIsFocused(true)}
                  onBlur={(e) => {
                    // 检查另一个输入框是否获得了焦点
                    const relatedTarget = e.relatedTarget as HTMLElement;
                    if (!relatedTarget?.classList.contains('cor-send-mail-modal-form-content-content')) {
                      setIsFocused(false);
                    }
                  }}
                />
              </Form.Item>
            </div>
            <div className="cor-send-mail-modal-form-btns">
              <Button
                loading={sendingEmail}
                type="primary"
                disabled={!formValid}
                htmlType="submit"
                icon={<SendOutlined width={16} height={16} />}>
                Send
              </Button>
            </div>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default SendMailModal;
