import { createSlice, current } from '@reduxjs/toolkit';
import { setKnowledgeMessage, getKnowledgeConversations, getknowledgeDirections } from './actions';
import _ from 'lodash';
import { AppState } from '@auth0/auth0-react';
import {
  ListArrayStatus,
  getDefaultListArrayStatus,
  setFulfilledEndListArrayStatus,
  setFulfilledListArrayStatus,
} from '@/types/redux';
import { FETCHING_STATUS } from '@/constants';
import { MESSAGE_STATUS } from '@/constants/demo';
import { IKnowledgeMessage } from '@/types/messages';
import { IKnowledge } from '@/types/knowledge';
export interface IKnowledgeState {
  messages: ListArrayStatus<IKnowledgeMessage>;
  replying: boolean;
  knowledges: {
    [ticketId: string]: ListArrayStatus<IKnowledge>;
  };
  knowledgeSavingStatus: FETCHING_STATUS;
}

const initialState: IKnowledgeState = {
  messages: getDefaultListArrayStatus(),
  replying: false,

  knowledges: {},
  knowledgeSavingStatus: FETCHING_STATUS.DONE,
};

export const knowledgeSlice = createSlice({
  name: 'knowledge',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setKnowledgeMessage, (state, { payload }) => {
      if (payload.message.status === MESSAGE_STATUS.FINISHED) {
        state.replying = false;
      } else {
        state.replying = true;
        const messages = _.get(state.messages, ['list'], []);
        const lastMessage = _.last(messages);
        if (lastMessage?.id === payload.message.id) {
          _.set(
            state.messages,
            ['list'],
            [
              ..._.initial(messages),
              {
                ...payload.message,
                createdAt: lastMessage.createdAt,
                content: (lastMessage?.content || '') + (payload.message?.content || ''),
              },
            ],
          );
        } else {
          _.set(state.messages, ['list'], [...messages, payload.message]);
        }
      }
    });

    builder
      .addCase(getKnowledgeConversations.pending, (state, { meta }) => {
        _.set(state.messages, [meta.arg.sessionId || '', 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getKnowledgeConversations.fulfilled, (state, { payload, meta }) => {
        if (payload) {
          _.set(state.messages, [meta.arg.sessionId || ''], setFulfilledListArrayStatus(payload));
        }
      });

    builder.addCase(getknowledgeDirections.pending, (state, {}) => {
      _.set(state.knowledges, ['status'], FETCHING_STATUS.FETCHING);
    });
    builder.addCase(getknowledgeDirections.fulfilled, (state, { payload }) => {
      _.set(state.knowledges, [], setFulfilledEndListArrayStatus(payload.data));
    });
  },
});

export const selectKnowledge = (state: AppState): IKnowledge[] | undefined =>
  _.get(state.knowledge.knowledges, ['', 'list'], []);

export const selectKnowledgeStatus = (state: AppState): FETCHING_STATUS | undefined =>
  _.get(state.knowledge.knowledges, ['', 'status'], FETCHING_STATUS.DONE);

export const selectKnowledgeSavingStatus = (state: AppState): FETCHING_STATUS => state.knowledge.knowledgeSavingStatus;

export const selectReplying = (state: AppState): boolean => state.knowledge.replying;
export const selectKnowledgeMessages = (state: AppState): IKnowledgeMessage[] | undefined =>
  _.get(state.knowledge.sessionMessages, ['list']);
export const selectKnowledgeMessagesStatus = (state: AppState): FETCHING_STATUS | undefined =>
  _.get(state.knowledge.sessionMessages, ['status']);

export default knowledgeSlice.reducer;
