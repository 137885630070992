/**
 * @description Component-InvoiceTable
 */
import React, { FC, useMemo } from 'react';
import './index.less';
import { IInvoice } from '@/types/subscription';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import Table, { ColumnType } from 'antd/lib/table';
import { FETCHING_STATUS } from '@/constants';
import { useSubscription } from '@/features/subscription/hooks';
import { Card } from 'antd';
interface IPropTypes {
  className?: string;
}
const InvoiceTable: FC<IPropTypes> = function () {
  const subscriptionItem = useSubscription();
  const pagination = useMemo(() => {
    return {
      total: subscriptionItem?.list?.length,
      showTotal: (total: number) => `Total ${total} items`,
    };
  }, [subscriptionItem?.list]);
  const columDefs: ColumnType<IInvoice>[] = useMemo(
    () => [
      {
        title: 'Month',
        key: 'month',
        align: 'left',
        ellipsis: true,
        render: (_value: any, record: IInvoice) => moment(record.period_start * 1000).format('MM/YYYYY'),
      },

      {
        title: 'Amount',
        key: 'Amount',
        ellipsis: true,
        align: 'right',
        render: (_value: any, record: IInvoice) => record.total / 100,
      },
      {
        title: 'Status',
        key: 'status',
        ellipsis: true,
        align: 'right',
        render: (_value: any, record: IInvoice) => record.status,
      },
      {
        title: 'Export',
        key: 'export',
        align: 'right',
        render: (_value: any, record: IInvoice) => (
          <div className="cor-subscription-invoices-actions">
            <a href={record.invoice_pdf} download>
              <DownloadOutlined />
            </a>
          </div>
        ),
      },
    ],
    [],
  );
  return (
    <Card bordered={false} title={'Invoices'}>
      <Table
        className="cor-subscription-invoice-table"
        loading={subscriptionItem?.status === FETCHING_STATUS.FETCHING}
        rowKey={(record) => record?.id}
        columns={columDefs}
        dataSource={subscriptionItem?.list}
        pagination={pagination}
      />
    </Card>
  );
};

export default InvoiceTable;
