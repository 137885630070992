import React from 'react';
import { List, Empty, Button, Modal } from 'antd';
import moment from 'moment';
import './index.less';
import { IComment } from '@/types/comment';
import Avatar from 'react-avatar';
import { delComment } from '@/features/comment/actions';
import { useAppDispatch } from '@/features/hooks';
import { useAuth0 } from '@auth0/auth0-react';

interface CommentListProps {
  comments: IComment[];
  onEditComment: (comment: IComment) => void; // 新增的回调函数
}

const CommentList: React.FC<CommentListProps> = ({ comments, onEditComment }) => {
  const { user } = useAuth0();
  const dispatch = useAppDispatch();

  const handleOpen = async (responseURL: string) => {
    window.open(`${process.env.REACT_APP_API_ROOT_V2}s3/file?url=${responseURL}`);
  };

  const onDelComment = (item: IComment) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this comment?',
      onOk: async () => {
        await dispatch(
          delComment({
            id: item.id,
            ticketId: item.ticketId,
          }),
        ).unwrap();
      },
      onCancel() {
        console.log('Delete action cancelled');
      },
      okText: 'Yes',
      cancelText: 'No',
      className: 'delete-confirm-modal',
    });
  };

  return (
    <div className="cor-comment-list-card">
      {comments.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={comments}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar round size="46" name={item.authorName} />}
                title={
                  <div className="cor-comment-list-card-title">
                    <span>{item.authorName}</span>
                    <span>{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</span>
                  </div>
                }
                description={
                  <div className="cor-comment-list-card-comment">
                    {/* <span>{item.author}</span> */}
                    <div>{item.content}</div>
                    {/* 展示附件 */}
                    {item.attachments && item.attachments.length > 0 && (
                      <List
                        className="comment-attachment-list"
                        dataSource={item.attachments}
                        renderItem={(attachment, attachmentIndex) => (
                          <List.Item key={attachmentIndex}>
                            <div className="link" onClick={() => handleOpen(attachment.attach)}>
                              {attachment.name}
                            </div>
                          </List.Item>
                        )}
                      />
                    )}
                    {user?.email === item.author && (
                      <div className="cor-comment-list-card-comment-btn">
                        <Button type="link" onClick={() => onEditComment(item)} className="edit-button">
                          Edit
                        </Button>
                        <Button type="link" onClick={() => onDelComment(item)} className="delete-button">
                          Delete
                        </Button>
                      </div>
                    )}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      ) : (
        <Empty description="No comments" />
      )}
    </div>
  );
};

export default CommentList;
