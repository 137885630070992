import React, { LegacyRef } from 'react';
import { Button, Tooltip } from 'antd';
import FormattedDate from '@/components/FormattedDate';
import CustomerDrawer from '@/pages/Customers/CustomerDrawer';
import { ReactComponent as RepliedIcon } from '@/assets/svg/check.svg';
import { ReactComponent as ArchiveIcon } from '@/assets/svg/icon_archive.svg';
import './index.less';

interface ListItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  ref: LegacyRef<HTMLDivElement> | undefined;
  currentId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect: (item: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onArchive: (e: React.MouseEvent<HTMLElement, MouseEvent>, item: any) => void;
}

const ListItem = React.forwardRef<HTMLDivElement, ListItemProps>(({ item, currentId, onSelect, onArchive }, ref) => {
  return (
    <div
      ref={ref}
      onClick={() => onSelect(item)}
      className={`cor-list-item ${item.from === currentId ? 'selected' : ''} ${item.unread ? 'unread' : ''}`}
      key={item.id}>
      <div className="cor-list-item-left" />
      <div className="cor-list-item-right">
        <div className="cor-list-item-name">
          <div className={`cor-list-item-name-left ${item.from === currentId ? 'active' : ''}`}>
            {item.from || item.name}
          </div>
          <div className="cor-list-item-name-right">
            <FormattedDate date={item?.date} />
          </div>
        </div>
        <div className="cor-list-item-subject">{item.latestSubject}</div>
      </div>
      <div className="cor-list-item-tags">
        <CustomerDrawer email={item.from} />
        <Tooltip title="Archive">
          <Button type="text" className="cor-list-item-archive" onClick={(e) => onArchive(e, item)}>
            <ArchiveIcon />
          </Button>
        </Tooltip>
        {item.replied && (
          <Tooltip title="Replied">
            <RepliedIcon />
          </Tooltip>
        )}
      </div>
    </div>
  );
});
ListItem.displayName = 'ListItem';
export default ListItem;
