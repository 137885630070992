import React, { FC, useState } from 'react';
import './index.less';
interface IPropTypes {
  className?: string;
  onStatusChange: (val: string, id?: string) => void;
}

const DocumentsTabs: FC<IPropTypes> = function ({  onStatusChange }) {
  const [current, setCurrent] = useState('published');
  const handleClick = (tab: string) => {
    onStatusChange(tab);
    setCurrent(tab);
  };

  return (
    <div className="cor-documentsTabs">
      <div className="cor-documentsTabs-left">
        <div
          className={`cor-documentsTabs-left-item ${current == 'published' ? 'active' : ''}`}
          onClick={() => handleClick('published')}>
          Published{' '}
        </div>
        <div
          className={`cor-documentsTabs-left-item ${current == 'drafts' ? 'active' : ''}`}
          onClick={() => handleClick('drafts')}>
          Drafts{' '}
        </div>
        <div
          className={`cor-documentsTabs-left-item ${current == 'expired' ? 'active' : ''}`}
          onClick={() => handleClick('expired')}>
          Expired{' '}
        </div>
      </div>

    </div>
  );
};

export default DocumentsTabs;
