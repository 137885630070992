/**
 * @description Component-Header
 */
import React, { FC, useCallback, useMemo, useState } from 'react';
import './index.less';
import { Button, Drawer, Menu, MenuProps } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
// import HeaderDropDown from '@/pages/components/HeaderDropDown';
import Logo from '@/pages/components/Logo';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MenuIcon } from './assets/menu.svg';
interface IPropTypes {
  className?: string;
  isDisclosure?: boolean;
}
const Header: FC<IPropTypes> = function ({}) {
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const login = useCallback(async () => {
    try {
      await loginWithRedirect();
    } catch (e) {}
  }, [loginWithRedirect]);
  const loginClick = useCallback(async () => {
    try {
      await logout();
    } catch (e) {}
  }, [loginWithRedirect]);
  // const scrollToProducts = useCallback(() => {
  //   const productEl = document.getElementById('products');
  //   if (productEl) {
  //     productEl.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, []);
  const menuItems = useMemo(
    () => [
      {
        key: 'company',
        label: <MenuIcon />,
        children: [
          { key: 'dashboard', label: t('landing.header.dashboard') },
          { key: 'about', label: t('landing.header.aboutUs') },
          { key: 'career', label: t('landing.header.careers') },
        ],
      },
    ],
    [t],
  );
  const onClick: MenuProps['onClick'] = useCallback(
    (e: { key: string }) => {
      navigate(`/${e.key}`);
    },
    [navigate],
  );
  return (
    <div className={`cor-mobileheader`}>
      <Logo />
      <div className="cor-mobileheader-btns">
        <>
          <Button type="text" onClick={() => setOpen(true)}>
            <MenuIcon />
          </Button>
        </>
      </div>
      <Drawer title={<Logo />} className="cor-mobileheader-drawer" open={open} onClose={() => setOpen(false)}>
        {!isAuthenticated && (
          <Button type="primary" onClick={login}>
            {t('landing.header.signUpIn')}
          </Button>
        )}
        <Button type="text" onClick={() => navigate('/dashboard')}>
          {t('landing.header.dashboard')}
        </Button>
        <Button type="text" onClick={() => navigate('/about')}>
          {t('landing.header.aboutUs')}
        </Button>
        <Button type="text" onClick={() => navigate('/career')}>
          {t('landing.header.careers')}
        </Button>
        {isAuthenticated && (
          <Button type="text" onClick={loginClick}>
            Logout
          </Button>
        )}
      </Drawer>
    </div>
  );
};

export default Header;
