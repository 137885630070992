import { SOCKET_CHANNELS } from '@/constants/sockets';
import { IDemoMessage, IDemoSession, ILanguage, IMerchant } from '@/types/demo';
import { IResponse } from '@/types/settings';
import { axiosGet } from '@/utils/axios';
import { AppState } from '@auth0/auth0-react';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Socket, io } from 'socket.io-client';
const URL = process.env.REACT_APP_WSS || '';
const apiRoot = (process.env.REACT_APP_API_ROOT_V2 || '') + 'livedemo';
export const setSelectedMerchant = createAction<IMerchant>('demo/setSelectedMerchant');
export const setMerchantSession = createAction<{ merchantId: string; sessionId: string }>('demo/setMerchantSession');
export const setSessionMessage = createAction<{ sessionId: string; message: IDemoMessage }>(
  'demo/setMerchantConversation',
);
export const getMerchants = createAsyncThunk(
  'demo/getMerchants',
  async ({
    pageSize,
    nextId,
    preId,
    search,
  }: {
    pageSize?: number;
    nextId?: string;
    preId?: string;
    search?: string;
  }): Promise<IResponse<IMerchant[]>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}livedemo/merchants`, {
        params: {
          pageSize,
          nextId,
          preId,
          search,
        },
      });
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const getDemoLanguages = createAsyncThunk(
  'demo/getDemoLanguages',
  async ({
    pageSize,
    nextId,
    preId,
    search,
  }: {
    pageSize?: number;
    nextId?: string;
    preId?: string;
    search?: string;
  }): Promise<IResponse<ILanguage[]>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}livedemo/languages`, {
        params: {
          pageSize,
          nextId,
          preId,
          search,
        },
      });
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const initMerchantSocket = createAsyncThunk(
  'demo/initMerchantSocket',
  async (
    {
      merchantId,
      userId,
    }: {
      merchantId: string;
      userId: string;
    },
    { getState },
  ): Promise<Socket | undefined> => {
    try {
      const {
        demo: { merchantSocket },
      } = getState() as AppState;
      if (_.get(merchantSocket, [merchantId])) {
        return undefined;
      }
      const socket = io(`${URL}/${SOCKET_CHANNELS.LIVE_DEMO}?trackId=${merchantId}&userId=${userId}`, {
        transports: ['websocket'],
        upgrade: true,
        forceNew: true,
      });
      socket.connect();
      return socket;
    } catch (e) {
      return undefined;
    }
  },
);
export const startSession = createAsyncThunk(
  'demo/startSession',
  async ({ merchantId, force }: { merchantId: string; force?: boolean }, { getState }): Promise<string | undefined> => {
    try {
      const {
        demo: { merchantSessionId },
      } = getState() as AppState;
      const sessionId = merchantSessionId[merchantId];
      if (sessionId && !force) {
        return undefined;
      }
      const result = await axiosGet(`${apiRoot}/startSession`);
      return result.data.data.sessionId;
    } catch (e) {
      return undefined;
    }
  },
);
export const getMerchantSessions = createAsyncThunk(
  'demo/getMerchantSessions',
  async ({
    trackId,
    pageSize,
    nextId,
  }: {
    trackId: string;
    pageSize?: number;
    nextId?: string;
  }): Promise<IDemoSession[] | undefined> => {
    try {
      const result = await axiosGet(`${apiRoot}/sessions`, {
        params: {
          trackId,
          pageSize,
          nextId,
        },
      });
      return _.filter(result.data.data, (s) => !!s.content);
    } catch (e) {
      return undefined;
    }
  },
);
export const getDemoConversations = createAsyncThunk(
  'demo/getDemoConversations',
  async ({ sessionId }: { sessionId: string }, { getState }): Promise<IDemoMessage[] | undefined> => {
    try {
      const {
        demo: { sessionReplying },
      } = getState() as AppState;
      const replying = sessionReplying[sessionId];
      if (replying) {
        return undefined;
      }
      const result = await axiosGet(`${apiRoot}/conversations`, {
        params: {
          sessionId,
        },
      });
      return _.tail(result.data.data);
    } catch (e) {
      return undefined;
    }
  },
);
