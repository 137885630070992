/**
 * @description Component-Playground
 */
import React, { FC } from 'react';
import './index.less';
import PlaygroundChat from './PlaygroundChat';
import PlaygroundReference from './PlaygroundReference';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const Playground: FC<IPropTypes> = function (props) {
  return (
    <div className="cor-playground">
      <PlaygroundChat />
      <PlaygroundReference />
    </div>
  );
};

export default Playground;
