import React, { FC, useState } from 'react';
import { ReactComponent as AddIcon } from '@/assets/svg/upload.svg';
import { Button, Modal, Input } from 'antd';
import { useAppDispatch } from '@/features/hooks';
import { addknowledgebase } from '@/features/knowledge/actions';
interface IPropTypes {
  className?: string;
}

const AddUrl: FC<IPropTypes> = function () {
  const dispatch = useAppDispatch();
  const [url, setUrl] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false); // 添加 loading 状态

  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  const handleSend = () => {
    setLoading(true); // 设置 loading 状态
    dispatch(
      addknowledgebase({
        category: 'url',
        source: url ,
      }),
    )
      .unwrap()
      .then(() => {
        setUrl('');
        handleCancel();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false); // 请求完成后重置 loading 状态
      });
  };

  return (
    <div>
      <Button className="cor-knowledge-head-btn-enbtn" icon={<AddIcon />} type="primary" onClick={showModal}>
        Add URL
      </Button>
      <Modal title="Add URL" visible={isModalVisible} onCancel={handleCancel} className="comment-modal" footer={null}>
        <Input className="comment-input" placeholder="Enter URL" value={url} onChange={(e) => setUrl(e.target.value)} />
        <Button loading={loading} disabled={loading} type="primary" onClick={handleSend} className="send-button">
          Send
        </Button>
      </Modal>
    </div>
  );
};

export default AddUrl;
