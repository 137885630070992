/**
 * @description Component-ChatReplyBox
 */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input, Radio } from 'antd';
import { ReactComponent as SendOutlined } from '@/assets/svg/icon_btnsend.svg';
import { getUserMailList, setAigcResponse, setInboxCurrentText } from '@/features/inbox/actions';
import { sendMail } from '@/features/inbox/actions';
import { useAppDispatch } from '@/features/hooks';
import { useOrg, useSignature } from '@/features/settings/hooks';
import {
  useCurrentUserId,
  useCurrentUserText,
  useUserById,
  useUserMailList,
  useSendingMail,
  useGettingAigcResponse,
  useAigcResponse,
  useUserSentMail,
} from '@/features/inbox/hooks';
import { useTemplates } from '@/features/settings/hooks';
import './index.less';
import { useSocket } from '@/features/socket/hooks';
import { FETCHING_STATUS } from '@/constants';
import { ISocketResponse } from '@/types/socket';
import { SOCKET_CHANNELS } from '@/constants/sockets';
import { toast } from 'react-toastify';
import EditTicket from '@/pages/Tickets/EditTicket';
import { ReactComponent as AddIcon } from '@/assets/svg/icon-add.svg';
interface IPropTypes {
  className?: string;
}

const ChatReplyBox: FC<IPropTypes> = function () {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const userId = useCurrentUserId(org?.id);
  const templates = useTemplates(org?.id);
  const currentText = useCurrentUserText(org?.id, userId);
  const user = useUserById(org?.id, userId);
  const mailList = useUserMailList(org?.id, userId);
  const sendingMail = useSendingMail(user?.from);
  const [lastMailId, setLastMailId] = useState<string>();
  const aigcResponse = useAigcResponse(lastMailId);
  const gettingAigcResponse = useGettingAigcResponse();
  const [isCommandPressed, setIsCommandPressed] = useState(false);
  const sentMail = useUserSentMail(org?.id, userId);
  const [signatures] = useSignature(org?.id);
  const [signatureText, setSignatureText] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const socket = useSocket(SOCKET_CHANNELS.MAIL);
  const inputMessage = (messageText: string) => {
    org &&
      userId &&
      dispatch(
        setInboxCurrentText({
          id: userId,
          orgId: org.id,
          text: messageText,
        }),
      );
  };
  const [radioValue, setRadioValue] = useState<string>('');

  // const onPickEmoji = (emoji: string) => {
  //   org &&
  //     userId &&
  //     dispatch(
  //       setInboxCurrentText({
  //         id: userId,
  //         orgId: org.id,
  //         text: `${currentText}${emoji}`,
  //       }),
  //     );
  // };

  // const onRadioChange = (e: RadioChangeEvent) => {
  //   setRadioValue(e.target.value);
  // };
  useEffect(() => {
    if (signatures?.signature) {
      setSignatureText(signatures.signature);
    }
  }, [signatures]);

  useEffect(() => {
    if (templates && templates?.length) {
      setRadioValue(templates[0].id as string);
    }
  }, [templates]);

  const dispatchSendMail = useCallback(() => {
    dispatch(
      sendMail({
        orgId: org?.id,
        to: user?.from,
        subject: '',
        body: signatureText ? `${currentText}\n${signatureText}` : currentText,
        mailId: lastMailId as string,
      }),
    );
  }, [dispatch, org, currentText, signatureText, user, lastMailId]);

  // const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Meta' || e.key === 'Control') {
      setIsCommandPressed(true);
    }
    if (isCommandPressed && e.key === 'Enter') {
      e.preventDefault();
      if (currentText) {
        console.log('send');
        dispatchSendMail();
      }
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Meta' || e.key === 'Control') {
      setIsCommandPressed(false);
    }
  };

  const scrollToBottom = useCallback(() => {
    containerRef.current &&
      containerRef.current?.scrollTo({ top: containerRef.current.scrollHeight, behavior: 'smooth' });
  }, []);
  const generateResponse = useCallback(() => {
    const lastMail = mailList?.[mailList?.length - 1];
    if (socket && lastMail) {
      if (!socket.connected) {
        socket.connect();
      }
      socket.emit('message', {
        mailId: lastMail.id as string,
        question: lastMail.text || '',
        templateId: radioValue || '',
      });
      dispatch(
        setAigcResponse({
          mailId: lastMail?.id,
          response: '',
          status: FETCHING_STATUS.INIT,
        }),
      );
    }
  }, [dispatch, mailList, radioValue, socket]);
  const onResponse = useCallback(
    (data: ISocketResponse<string>) => {
      if (data.error) {
        toast.error(data.error);
        org &&
          lastMailId &&
          dispatch(
            setAigcResponse({
              response: '',
              mailId: lastMailId,
              status: FETCHING_STATUS.DONE,
            }),
          );
        return;
      }
      data &&
        dispatch(
          setAigcResponse({
            mailId: data.mailId,
            response: data.data || '',
            status: data.status ? FETCHING_STATUS.DONE : FETCHING_STATUS.FETCHING,
          }),
        );
      scrollToBottom();
      textareaRef.current?.focus();
    },
    [dispatch, org, lastMailId, scrollToBottom],
  );

  useEffect(() => {
    if (sendingMail) {
      return;
    }
    org &&
      dispatch(
        setInboxCurrentText({
          id: userId,
          orgId: org.id,
          text: '',
        }),
      );
  }, [dispatch, lastMailId, org, userId, sendingMail]);
  useEffect(() => {
    if (!lastMailId || !sentMail) {
      return;
    }
    org &&
      dispatch(getUserMailList({ orgId: org?.id as string, from: user?.from, mode: 'append', nextId: lastMailId }));
  }, [dispatch, org, user, lastMailId, sentMail]);
  useEffect(() => {
    org &&
      userId &&
      dispatch(
        setInboxCurrentText({
          id: userId,
          orgId: org.id,
          text: aigcResponse,
        }),
      );
  }, [dispatch, org, userId, aigcResponse]);
  useEffect(() => {
    if (!mailList?.length) {
      return;
    }
    setLastMailId(mailList[mailList.length - 1]?.id);
  }, [setLastMailId, mailList]);
  useEffect(() => {
    setLastMailId('');
  }, [userId]);
  useEffect(() => {
    if (socket) {
      socket.on('response', onResponse);
    }
    return () => {
      socket && socket.off('response', onResponse);
    };
  }, [dispatch, mailList, onResponse, radioValue, socket]);

  return (
    <div className="cor-chat-reply-mail-box">
      <div className="cor-chat-reply-mail-box-input-container">
        <div className="header">
          <div className="radio-box">
            {/* <Radio.Group onChange={onRadioChange} value={radioValue}>
              {(templates || []).map((it) => (
                <Radio key={it.id} value={it.id}>
                  {it.name}
                </Radio>
              ))}
            </Radio.Group> */}
          </div>
          <div className="btns">
            <EditTicket
              initialValues={{ customerEmail: user?.from, type: 'manual', source: 'email' }}
              text="Create Ticket"
              title="Create Ticket"
              type="primary"
              icon={<AddIcon />}
            />
            <Button
              type="primary"
              className="normal-btn generate"
              disabled={!mailList?.length || !userId}
              onClick={generateResponse}
              loading={gettingAigcResponse}
              icon={<AddIcon />}>
              Generate Response
            </Button>
          </div>
        </div>
        <div className="textarea">
          <div className="top " ref={containerRef}>
            <Input.TextArea
              ref={textareaRef}
              onChange={(e) => inputMessage(e.target.value)}
              value={currentText}
              onKeyUp={(e) => handleKeyUp(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              autoSize={{ minRows: 7 }}
              placeholder="Type here or Click Generate Response to reply"
              bordered
            />
          </div>
          {signatureText && (
            <Input.TextArea
              onChange={(e) => setSignatureText(e.target.value)}
              value={signatureText}
              className="email-signature"
              autoSize={{ minRows: 2, maxRows: 2 }}
              bordered
            />
          )}
        </div>
        <div className="cor-chat-reply-mail-box-input-container-bottom">
          <Button
            type="primary"
            className="normal-btn bluesend"
            onClick={dispatchSendMail}
            disabled={!currentText}
            loading={sendingMail}>
            {sendingMail ? <></> : <SendOutlined />}
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChatReplyBox;
