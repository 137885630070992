import { IResponse } from '@/types/settings';
import { IAlloy, IShopConnection } from '@/types/shop';
import { axiosGet, axiosPost, axiosDelete } from '@/utils/axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const setAlloy = createAction<IAlloy>('shop/setAlloy');

export const getAlloyToken = createAsyncThunk('shop/getAlloyToken', async (): Promise<IResponse<{ token: string }>> => {
  try {
    const { data } = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}alloy/token`);
    return data;
  } catch (e) {
    console.log('🚀 ~ file: actions.ts:22 ~ e:', e);
    throw e;
  }
});
export const getShopConnections = createAsyncThunk(
  'shop/getShopConnections',
  async (): Promise<IResponse<IShopConnection[]>> => {
    try {
      const data = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}alloy/connections`);
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:22 ~ e:', e);
      throw e;
    }
  },
);
export const deleteShopConnection = createAsyncThunk(
  'shop/deleteShopConnection',
  async ({ connectionId }: { connectionId: string }): Promise<IResponse<boolean>> => {
    try {
      const data = await axiosDelete(`${process.env.REACT_APP_API_ROOT_V2}alloy/connections/${connectionId}`);
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:22 ~ e:', e);
      throw e;
    }
  },
);
