import React, { FC, useEffect, useState } from 'react';
import './index.less';
import { useKnowledgebase } from '@/features/knowledge/hooks';
import moment from 'moment';
import { Button } from 'antd';
interface IPropTypes {
  className?: string;
  tag: string;
  status: string;
  onIdChange: (id: string) => void;
}

const DocumentsCard: FC<IPropTypes> = ({ onIdChange, tag, status }) => {
  const knowledgeList = useKnowledgebase();
  const [currentId, setCurrentId] = useState<string>('');

  useEffect(() => {
    if (currentId) {
      onIdChange(currentId);
    }
  }, [currentId, onIdChange]);

  console.log('knowledgeList----', knowledgeList);

  return (
    <div className="cor-documentsCard">
      <div className="cor-documentsCard-head">
        <div>search results for “{tag}”</div>
        {/* {status == 'drafts' && (
          <Button className="cor-documentsCard-head-allbtn" type="primary">
            Unpublish All
          </Button>
        )} */}
      </div>

      <div className="cor-documentsCard-center">
        {knowledgeList &&
          knowledgeList.map((item) => (
            <div key={item.id} className="cor-documentsCard-center-item" onClick={() => setCurrentId(item.id)}>
              <div className="cor-documentsCard-center-item-title">{item.title}</div>
              <div className="cor-documentsCard-center-item-content">{item.content}</div>
              <div className="cor-documentsCard-center-item-tag">
                {item.tags &&
                  item.tags.map((tag: { name: string }, index: number) => <div key={index}>{tag.name}</div>)}
              </div>
              <div className="cor-documentsCard-center-item-tag">
                Last updated {moment(item.updatedAt).format('YYYY-MM-DD')}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DocumentsCard;
