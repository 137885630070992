import React, { FC, useMemo } from 'react';
import './index.less';
import classNames from 'classnames';
import { useOrg } from '@/features/settings/hooks';
import {
  useSelectTicketGetParam,
  useSelectTicketListTotal,
  useTickets,
  useTicketsStatus,
} from '@/features/plugin/hooks';
import { DATE_FORMAT, FETCHING_STATUS } from '@/constants';
import { Table, Tabs, ConfigProvider, Empty } from 'antd';
import { ITicket } from '@/types/plugin';
import { ColumnType, TableProps } from 'antd/lib/table';

import TicketGlobalEffect from './globalEffect';
import moment from 'moment';
import TeamGlobalEffect from '@/features/team/globalEffect';
import Assignee from './Assignee';
import { setTicketGetParam } from '@/features/plugin/actions';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/features/hooks';
import { TicketParamSort, TicketParamSortBy } from '@/features/plugin/slice';
import DeleteTicket from './DeleteTicket';
import QueryHeader from './QueryHeader';
import { ReactComponent as NoFound } from '@/assets/svg/icon_no_found.svg';
import PrioritySelect from './PrioritySelect';
import StatusSelect from './StatusSelect';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const stopPropagation = (e: { stopPropagation: () => void }) => {
  e.stopPropagation();
};

const tableEmptyRender = () => {
  return <Empty className="cor-tickets-empty" image={<NoFound />} description={<>No Tickets Found</>}></Empty>;
};

const Tickets: FC<IPropTypes> = () => {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const tickets = useTickets(org?.id);
  const ticketsStatus = useTicketsStatus(org?.id);
  const param = useSelectTicketGetParam();
  const total = useSelectTicketListTotal();
  const navigate = useNavigate();
  const items = [
    {
      key: 'Tickets',
      label: 'Tickets',
    },
  ];
  const columDefs: ColumnType<ITicket>[] = useMemo(
    () => [
      {
        title: 'Subject',
        key: 'subject',
        align: 'left',
        ellipsis: true,
        render: (_value, record) => record.title,
      },
      {
        title: 'Customer Email',
        key: 'customereEmail',
        ellipsis: true,
        render: (_value, record) => record.customerEmail,
      },
      {
        title: 'Assignee',
        key: 'assignee',
        ellipsis: true,
        sorter: true,
        align: 'right',
        width: 240,
        render: (_value, record) => <Assignee ticket={record} />,
        onCell: () => ({
          onClick: stopPropagation, // 阻止事件冒泡
        }),
      },
      {
        title: 'Status',
        key: 'status',
        ellipsis: true,
        sorter: true,
        align: 'right',
        render: (_value, record) => (
          <StatusSelect ticket={record} />
          // <span className={classNames('cor-tickets-status', `${record.status}`)}> {record.status}</span>
        ),
      },
      {
        title: 'Priority',
        key: 'priority',
        ellipsis: true,
        sorter: true,
        align: 'right',
        render: (_value, record) => (
          // <span className={classNames('cor-tickets-status', `${record.priority}`)}> {record.priority}</span>
          <PrioritySelect ticket={record} />
        ),
      },
      {
        title: 'Created Time',
        key: 'createdAt',
        ellipsis: true,
        sorter: true,
        defaultSortOrder: param.sort == TicketParamSort.ASC ? 'ascend' : 'descend',
        align: 'right',
        render: (_value, record) => moment(record.updatedAt).format(DATE_FORMAT),
      },
      {
        title: '',
        key: 'action',
        align: 'right',
        width: 100,
        render: (_value, record) => (
          <div className="cor-tickets-actions">
            {/* <EditTicket text="Edit" title="Edit" type="primary" ticketToEdit={record} icon={<EditIcon />} /> */}
            <DeleteTicket id={record.id} />
          </div>
        ),
        onCell: () => ({
          onClick: stopPropagation, // 阻止事件冒泡
        }),
      },
    ],
    [param.sort],
  );

  const pagination = useMemo(
    () => ({
      pageSize: 10,
      total,
      showTotal: (total: number) => `Total tickets ${total}`,
      showSizeChanger: true,
    }),
    [total],
  );

  const onChange: TableProps<ITicket>['onChange'] = (pagination, _filters, sorter, _extra) => {
    dispatch(
      setTicketGetParam({
        page: pagination.current,
        sort: sorter?.order === 'ascend' ? TicketParamSort.ASC : TicketParamSort.DESC,
        sortBy: sorter?.columnKey === 'assignee' ? TicketParamSortBy.ASSIGNEES : sorter?.columnKey,
      }),
    );
  };

  const rowClassName = () => 'custom-table-row';

  return (
    <div className="cor-tickets">
      <Tabs className="cor-tickets-tabs" defaultActiveKey="Tickets" items={items} />
      <QueryHeader />
      <ConfigProvider renderEmpty={tableEmptyRender}>
        <Table
          loading={ticketsStatus === FETCHING_STATUS.FETCHING && !tickets?.length}
          rowKey={(record) => record?.id + record.updatedAt}
          columns={columDefs}
          dataSource={tickets}
          pagination={pagination}
          className="cor-tickets-table"
          rowClassName={rowClassName}
          onChange={onChange}
          onRow={(record) => ({
            onClick: () => {
              navigate(`/dashboard/tickets/${record.id}`); // 根据记录的 ID 跳转到 /dashboard/:id
            },
          })}
        />
      </ConfigProvider>
      <TicketGlobalEffect />
      <TeamGlobalEffect />
    </div>
  );
};

export default Tickets;
