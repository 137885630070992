/**
 * @description Component-QueryHeader
 */
import React, { FC, useMemo, useState } from 'react';
import './index.less';
import { Avatar, Button, Drawer, Checkbox, Input, Menu, Popover, Radio, Select, Space } from 'antd';
import classNames from 'classnames';
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseOutlined,
  DoubleRightOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { useOrg } from '@/features/settings/hooks';
import { useTeamMembers } from '@/features/team/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelectTicketGetParam } from '@/features/plugin/hooks';
import { setTicketGetParam } from '@/features/plugin/actions';
import { useAppDispatch } from '@/features/hooks';
import { useDebounceFn } from 'ahooks';
import { matchSorter } from 'match-sorter';
import { setTeamMembers } from '@/features/team/actions';
import { IMember } from '@/types/team';
import { TICKET_PRIORITY, TICKET_STATUS } from '@/constants/Tickets';
import { ReactComponent as AddIcon } from '@/assets/svg/icon_add.svg';
import EditTicket from './../EditTicket';
import { ReactComponent as SearchOutlined } from '@/assets/svg/icon_search_outlined.svg';
import { ReactComponent as UserOutlined } from '@/assets/svg/icon_user_outlined.svg';
import { ReactComponent as TeamOutlined } from '@/assets/svg/icon_team_outlined.svg';
const CheckboxGroup = Checkbox.Group;

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const QueryHeader: FC<IPropTypes> = function ({}) {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const { user } = useAuth0();
  const teams = useTeamMembers(org?.id);
  const param = useSelectTicketGetParam();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [all, setAll] = useState(false);

  const changeDrawer = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  // todo
  const handleMeClick = () => {
    setSearch('');
    setOpen(false);
    setAll(false);
    const isCurrentEmail = param.assignees?.includes(user?.email || '');
    handleSetTeamMembers(false, isCurrentEmail);

    // dispatch(
    //   setTicketGetParam({ page: 1, assignees: param.assignees?.join(',') === user?.email ? [] : [user?.email || ''] }),
    // );
  };

  const handleItemClick = (member: IMember) => {
    const list =
      teams?.map((item) => {
        return member.id === item.id
          ? {
              ...item,
              selected: !item.selected,
            }
          : item;
      }) || [];
    handleAction(list);

    // const assignees = list?.reduce((acc: string[], item) => {
    //   if (item.selected) {
    //     acc.push(item.email);
    //   }
    //   return acc;
    // }, []);
    // assignees.length === list.length ? setAll(true) : setAll(false);
    // dispatch(
    //   setTeamMembers({
    //     orgId: org?.id,
    //     members: list,
    //   }),
    // );
    // dispatch(
    //   setTicketGetParam({
    //     page: 1,
    //     assignees,
    //   }),
    // );
  };

  const handleSetTeamMembers = (selected: boolean, isCurrentEmail?: boolean) => {
    const email = user?.email;
    const list =
      teams?.map((item) => {
        return item.email === email
          ? { ...item, selected: typeof isCurrentEmail === 'boolean' ? !isCurrentEmail : selected }
          : isCurrentEmail
          ? { ...item }
          : { ...item, selected };
      }) || [];
    handleAction(list);

    // dispatch(
    //   setTicketGetParam({ page: 1, assignees: email ? [email] : selected ? list?.map((item) => item.email) : [] }),
    // );
  };

  const handleAction = (list: IMember[]) => {
    const assignees = list?.reduce((acc: string[], item) => {
      if (item.selected) {
        acc.push(item.email);
      }
      return acc;
    }, []);
    assignees?.length === list?.length ? setAll(true) : setAll(false);

    dispatch(
      setTeamMembers({
        orgId: org?.id,
        members: list,
      }),
    );
    dispatch(
      setTicketGetParam({
        page: 1,
        assignees,
      }),
    );
  };

  const handleAllClick = () => {
    handleSetTeamMembers(!all);
  };

  const filterTeams = useMemo(() => {
    const filterTeams = teams;
    if (search) {
      return matchSorter(filterTeams || [], search, {
        keys: ['email'],
        threshold: matchSorter.rankings.ACRONYM, // https://www.npmjs.com/package/match-sorter?activeTab=readme
      });
    }
    return filterTeams;
  }, [teams, search]);

  const checkAllTeams = useMemo(() => {
    const list = teams?.filter((member) => {
      return member.selected;
    });
    if (list && list?.length > 0) {
      return (
        <Avatar.Group size="small" maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
          {list.map((item) => (
            <Avatar key={item.id}>{item.email.toUpperCase().charAt(0)}</Avatar>
          ))}
        </Avatar.Group>
      );
    }
    return 'Assignees';
  }, [teams]);

  const onStatusChange = (value: TICKET_STATUS) => {
    dispatch(setTicketGetParam({ page: 1, status: value }));
  };

  const { run: onInputChange } = useDebounceFn(
    (str: string) => {
      dispatch(setTicketGetParam({ page: 1, query: str }));
    },
    { wait: 200 },
  );

  const radioOptions = useMemo(() => {
    const opt = [];
    for (const key in TICKET_STATUS) {
      if (TICKET_STATUS.hasOwnProperty(key)) {
        opt.push({
          label: key.charAt(0).toUpperCase() + key.toLowerCase().slice(1),
          value: TICKET_STATUS[key as keyof typeof TICKET_STATUS],
        });
      }
    }
    return opt;
  }, []);

  // const statusOptions = useMemo(() => {
  //   const opt = [];
  //   for (const key in TICKET_STATUS) {
  //     if (TICKET_STATUS.hasOwnProperty(key) && key !== 'ALL') {
  //       opt.push({
  //         label: key.charAt(0).toUpperCase() + key.toLowerCase().slice(1),
  //         value: TICKET_STATUS[key as keyof typeof TICKET_STATUS],
  //       });
  //     }
  //   }
  //   return opt;
  // }, []);

  // const PriorityOptions = useMemo(() => {
  //   const opt = [];
  //   for (const key in TICKET_PRIORITY) {
  //     if (TICKET_PRIORITY.hasOwnProperty(key) && key !== 'ALL') {
  //       opt.push({
  //         label: key.charAt(0).toUpperCase() + key.toLowerCase().slice(1),
  //         value: TICKET_PRIORITY[key as keyof typeof TICKET_PRIORITY],
  //       });
  //     }
  //   }
  //   return opt;
  // }, []);

  return (
    <div className="cor-query-header">
      <div className="cor-query-header-status">
        {radioOptions?.map((i) => (
          <Button
            type={param.status === i.value ? 'primary' : 'text'}
            shape="round"
            size="small"
            key={i.value}
            onClick={() => {
              onStatusChange(i.value);
            }}>
            {i.label}
          </Button>
        ))}
      </div>
      <div className="cor-query-header-bottom">
        <div className="cor-query-header-search">
          <Input
            value={param.query}
            size="small"
            prefix={<SearchOutlined />}
            placeholder="Search..."
            allowClear={{
              clearIcon: <CloseOutlined />,
            }}
            onChange={(e) => onInputChange(e.target.value.trim())}></Input>
        </div>
        <EditTicket text="Add Ticket" title="Create Ticket" type="primary" icon={<AddIcon />} />
      </div>
      <div className="cor-query-header-assignees">
        <span className="assignees-text">Filter by</span>
        {/* <Popover
          className="cor-query-header-multiple"
          overlayClassName="cor-query-header-multiple-popover"
          autoAdjustOverflow={false}
          content={
            <CheckboxGroup
              options={statusOptions}
              onChange={(checkedValues: CheckboxValueType[]) => {
                console.log('checked = ', checkedValues);
              }}
            />
          }
          placement="bottom"
          trigger="click"
          onOpenChange={() => console.log(111)}>
          <Button>
            Status <DownOutlined />
          </Button>
        </Popover> */}

        {/* <Popover
          className="cor-query-header-multiple"
          overlayClassName="cor-query-header-multiple-popover"
          autoAdjustOverflow={false}
          content={
            <CheckboxGroup
              options={PriorityOptions}
              onChange={(checkedValues: CheckboxValueType[]) => {
                console.log('checked = ', checkedValues);
              }}
            />
          }
          placement="bottom"
          trigger="click"
          onOpenChange={() => console.log(111)}>
          <Button>
            Priority <DownOutlined />
          </Button>
        </Popover> */}

        <Button
          type={param.assignees?.includes(user?.email || '') ? 'primary' : 'default'}
          size="small"
          icon={<UserOutlined />}
          shape="round"
          onClick={handleMeClick}>
          My tickets
        </Button>
        <Button size="small" icon={<TeamOutlined width="30" />} shape="round" onClick={changeDrawer}>
          {checkAllTeams}
        </Button>
      </div>

      <Drawer
        className="cor-query-header-drawer"
        title="Assignees"
        placement="right"
        onClose={onClose}
        maskStyle={{ background: 'none' }}
        open={open}
        width={250}
        closeIcon={<DoubleRightOutlined />}>
        <div className="cor-query-header-drawer-search">
          <Input
            value={search}
            size="small"
            prefix={<SearchOutlined />}
            placeholder="Search..."
            allowClear={{
              clearIcon: <CloseOutlined />,
            }}
            onChange={(e) => setSearch(e.target.value.trim())}></Input>
        </div>
        <div className="cor-query-header-drawer-team">
          {filterTeams?.map((member) => (
            <div
              onClick={() => {
                handleItemClick(member);
              }}
              className={classNames('cor-query-header-drawer-item', member.selected ? 'selected' : '')}
              key={member.id}>
              <Avatar className="cor-query-header-drawer-item-avatar">{member.email.toUpperCase().charAt(0)}</Avatar>
              <div className="cor-query-header-drawer-item-text">{member.email}-</div>
              <div className="cor-query-header-drawer-item-check">
                {member.selected ? <CheckCircleFilled /> : <CheckCircleOutlined />}
              </div>
            </div>
          ))}
        </div>
        <div className="cor-query-header-drawer-all">
          <Button type="text" onClick={handleAllClick}>
            {all ? 'clear all' : 'select all'}
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default QueryHeader;
