/**
 * @description Component-Subscription
 */
import React, { FC, useMemo } from 'react';
import './index.less';
import SubscriptionGlobalEffect from '@/features/subscription/globalEffect';
import { useUserInfo, useUserInfoStatus } from '@/features/user/hooks';
import { PRODUCTS_INFO } from '@/constants/subscription';
import _ from 'lodash';
import moment from 'moment';
import {  DATE_FORMAT_DETAIL, FETCHING_STATUS } from '@/constants';
import InvoiceTable from './InvoiceTable';
import { Skeleton } from 'antd';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const Subscription: FC<IPropTypes> = function (props) {
  const userInfo = useUserInfo();
  const userInfoStatus = useUserInfoStatus();
  const product = useMemo(() => _.get(PRODUCTS_INFO, [userInfo?.plan.id || '']), [userInfo?.plan.id]);

  return (
    <div className="cor-subscription">
      {userInfoStatus !== FETCHING_STATUS.DONE && !userInfo && <Skeleton active paragraph={{ rows: 2 }} />}
      {userInfo?.plan && (
        <div className="cor-subscription-account">
          <dl className="cor-subscription-account-plan">
            <dt>Plan</dt>
            <dd> {product?.name}</dd>
          </dl>
          <dl className="cor-subscription-account-price">
            <dt>Price: </dt>
            <dd>
              {userInfo.plan.amount / 100} {userInfo.currency?.toUpperCase()}/month
            </dd>
          </dl>
          <div className="cor-subscription-account-period">
            Started from: {moment(userInfo?.plan.created * 1000).format(DATE_FORMAT_DETAIL)}
          </div>
        </div>
      )}
      <div className="cor-subscription-invoices">
        <InvoiceTable />
      </div>
      <SubscriptionGlobalEffect />
    </div>
  );
};

export default Subscription;
