import { IInvoice } from '@/types/subscription';
import { axiosGet } from '@/utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
const apiRoot = (process.env.REACT_APP_API_ROOT_V2 || '') + 'stripe/';

export const getInvoices = createAsyncThunk('subscription/getInvoices', async (): Promise<IInvoice[]> => {
  try {
    const result = await axiosGet(`${apiRoot}invoices`);
    return result.data.data;
  } catch (e) {
    throw e;
  }
});
