/**
 * @description Component-PlaygroundChatItem
 */
import React, { FC } from 'react';
import './index.less';
import { IKnowledgeMessage } from '@/types/messages';
import { DATE_FORMAT_DETAIL } from '@/constants';
import { IMessageRole } from '@/types/aigc';
import MDEditor from '@uiw/react-md-editor';
import Avatar from 'react-avatar';
import stoneIcon from '@/assets/svg/stone.svg';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMediaQueryDevice } from '@/components/MediaQuery';
interface IPropTypes {
  message?: IKnowledgeMessage;
  className?: string;
  onInputMessageChange?: (message: string) => void;
}
const PlaygroundChatItem: FC<IPropTypes> = function ({ message }) {
  const { deviceType } = useMediaQueryDevice();
  return (
    <div className="cor-playground-chat-item">
      <div className={classNames('cor-playground-chat-item', message?.role, deviceType)}>
        <div className="cor-playground-chat-item-top">
          <div className="ant-avatar">
            {message?.role === IMessageRole.ASSISTANT ? (
              <img src={stoneIcon} width={24} height={24} />
            ) : (
              <Avatar className="ant-avatar" round size="24" name={'Me'} />
            )}
          </div>
          <div className="cor-playground-chat-item-content">
            <div className="cor-playground-chat-item-content-top">
              <div className="cor-playground-chat-item-timestamp">
                <div className="cor-playground-chat-item-timestamp-name">
                  {message?.role === IMessageRole.USER ? 'Me' : 'AI'}
                </div>
                <div className="date-and-option">
                  <span className="date">{dayjs(message?.createdAt).format(DATE_FORMAT_DETAIL)}</span>
                </div>
              </div>

              <MDEditor.Markdown className="cor-playground-chat-item-text" source={message?.content || ''} />
            </div>
          </div>
        </div>
        {/* {message?.examples && (
          <div className="cor-playground-chat-item-examples">
            {message?.examples?.map((e, index) => (
              <ExampleItem
                onInputMessageChange={onInputMessageChange}
                className="cor-playground-chat-item-examples-item"
                key={index}
                example={e}
              />
            ))}
          </div>
        )} */}
        {/* {recommendations.map((recommendation, index) => (
          <LinkPreviewer key={index} url={recommendation?.params?.productUrl || recommendation.params.youtubeUrl} />
        ))} */}
      </div>
    </div>
  );
};

export default PlaygroundChatItem;
