import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AppState } from '@auth0/auth0-react';
import { ListArrayStatus, getDefaultListArrayStatus, setFulfilledEndListArrayStatus } from '@/types/redux';
import { IKnowledgebaseTages, IKnowledgebase } from '@/types/knowledge';
import { getknowledgebaseTags, getKnowledgebase } from './actions';
import { FETCHING_STATUS } from '@/constants';

export interface IKnowledgeState {
  knowledgebase: ListArrayStatus<IKnowledgebase>;
  knowledgebaseTages: ListArrayStatus<IKnowledgebaseTages>;
}

const initialState: IKnowledgeState = {
  knowledgebase: getDefaultListArrayStatus(),
  knowledgebaseTages: getDefaultListArrayStatus(),
};

export const knowledgeSlice = createSlice({
  name: 'knowledge',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getknowledgebaseTags.pending, (state, { meta }) => {
      _.set(state.knowledgebaseTages, ['status'], FETCHING_STATUS.FETCHING);
    });
    builder.addCase(getknowledgebaseTags.fulfilled, (state, { payload, meta }) => {
      state.knowledgebaseTages = setFulfilledEndListArrayStatus(payload.data);
    });
    builder.addCase(getKnowledgebase.pending, (state, { meta }) => {
      _.set(state.knowledgebaseTages, ['status'], FETCHING_STATUS.FETCHING);
    });
    builder.addCase(getKnowledgebase.fulfilled, (state, { payload, meta }) => {
      state.knowledgebase = setFulfilledEndListArrayStatus(payload.data);
    });
  },
});

export const selectKnowledgebaseTags = (state: AppState): IKnowledgebaseTages[] | undefined =>
  _.get(state.knowledge.knowledgebaseTages, ['list'], []);

export const selectKnowledgebaseBase = (state: AppState): IKnowledgebase[] | undefined =>
  _.get(state.knowledge.knowledgebase, ['list'], []);
export const selectKnowledgebaseTagsStatus = (state: AppState): IKnowledgebase[] | undefined =>
  _.get(state.knowledge.knowledgebaseTages, ['status'], []);
export default knowledgeSlice.reducer;
