import { IKnowledgebaseTages, IKnowledgebase } from '@/types/knowledge';
import { useAppSelector } from '../hooks';
import { selectKnowledgebaseTags, selectKnowledgebaseBase, selectKnowledgebaseTagsStatus } from './slice';
export function useKnowledgebaseTags(): IKnowledgebaseTages[] | undefined {
  return useAppSelector(selectKnowledgebaseTags);
}
export function useKnowledgebase(): IKnowledgebase[] | undefined {
  return useAppSelector(selectKnowledgebaseBase);
}
export function useKnowledgebaseTagsStatus(): IKnowledgebase[] | undefined {
  return useAppSelector(selectKnowledgebaseTagsStatus);
}
