import React, { FC, useEffect, useState } from 'react';
import './index.less';
import Search from './search';
import DocumentknowledgeGlobalEffect from '@/features/knowledge/globalEffect';
import { useKnowledgebaseTags } from '@/features/knowledge/hooks';
import { DeleteOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useAppDispatch } from '@/features/hooks';
import {
  deleteKnowledgebaseTags
} from '@/features/knowledge/actions';
interface IPropTypes {
  className?: string;
  tag:{name:string,id:string}
  status:string
  tagsIdChange:(item:{name:string,id:string})=>void
  tabs:string
}

const DocumentsSidebar: FC<IPropTypes> = function ({tag,status,tagsIdChange,tabs}) {
  const dispatch = useAppDispatch();
  const tagsList = useKnowledgebaseTags();
  console.log('tagsList', tagsList);
  const [current, setCurrent] = useState<string | null>(null); // 当前选中的 item id
  // 当 knowledgeList 有值时，默认设置为第一个 item 的 id
  useEffect(() => {
    if (tagsList && tagsList.length > 0) {
      setCurrent(tagsList[0].id);
    }
  }, [tagsList]);

  const handleClick = (item:{name:string,id:string}) => {
    tagsIdChange(item)
    setCurrent(item.id);
  };

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tagToDelete, setTagToDelete] = useState<{name:string,id:string} | null>(null);

  const handleDeleteClick = (e: React.MouseEvent, item: {name:string,id:string}) => {
    e.stopPropagation();
    setTagToDelete(item);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    if (tagToDelete?.id) {
        await dispatch(deleteKnowledgebaseTags({tag_id: tagToDelete.id}));
        setDeleteModalVisible(false);
        setTagToDelete(null);
    }
  };

  return (
    <div className="cor-documentsSidebar">
      <Search tag={tag} status={status} tabs={tabs}/>
      {tagsList && tagsList.length > 0 ? (
        tagsList.map((item, index) => (
          <div
            className={`cor-documentsSidebar-item ${current === item.id ? 'active' : ''}`}
            key={item.id || index} 
            onClick={() => handleClick(item)}>
            {item.name}
            <DeleteOutlined 
              className="delete-icon"
              onClick={(e) => handleDeleteClick(e, item)}
            />
          </div>
        ))
      ) : (
        <div className="cor-documentsSidebar-empty">No documents available</div>
      )}
      <DocumentknowledgeGlobalEffect />
      <Modal
      className='deletetags-confirm-modal'
        title="Confirm Delete"
        open={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={() => setDeleteModalVisible(false)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>Deleting tag &quot;{tagToDelete?.name}&quot; will also delete all knowledge content under this tag. Are you sure you want to continue?</p>
      </Modal>
    </div>
  );
};

export default DocumentsSidebar;
