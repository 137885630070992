import { FETCHING_STATUS } from '@/constants';
import { AIGC_TYPE } from '@/constants/aigc';
import { IMessage } from '@/types/aigc';
import { IResponse } from '@/types/settings';
import { axiosGet, axiosPost } from '@/utils/axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const setBotMessage = createAction<{ message: IMessage; orgId: string; userEmail: string; scene: AIGC_TYPE }>(
  'aigc/setBotMessage',
);
export const setBotStatus = createAction<{ status: FETCHING_STATUS; orgId: string; userEmail: string }>(
  'aigc/setBotStatus',
);

export const getBotHistory = createAsyncThunk(
  'aigc/getBotHistory',
  async ({
    userEmail,
    nextId,
    scene,
  }: {
    userEmail: string;
    org: string;
    nextId?: string;
    scene: AIGC_TYPE;
  }): Promise<IResponse<IMessage[]> | undefined> => {
    try {
      const data = await axiosGet(
        `${process.env.REACT_APP_API_ROOT_V2}llm/chatbot/conversations/threads?scene=${
          AIGC_TYPE.CHATBOT
        }&from=${userEmail}&next=${nextId || ''}&scene=${scene}`,
      );
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:11 ~ getBotHistory ~ e:', e);
      return undefined;
    }
  },
);
