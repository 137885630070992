import { memo, useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { getOrgMailSettings, getSignature, getTemplate, getOrgSocialSettings, getAutoReplySettings } from './actions';
import { useMailSettings, useOrg, useTemplateFetchingStatus } from './hooks';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FETCHING_STATUS } from '@/constants';
import { toast } from 'react-toastify';

function useGetSettings() {
  const dispatch = useAppDispatch();
  const org = useOrg();
  useEffect(() => {
    org?.id && dispatch(getOrgMailSettings({ org: org.id }));
    org?.id && dispatch(getTemplate({ org: org.id }));
    org?.id && dispatch(getSignature({ org: org.id }));
    org?.id && dispatch(getOrgSocialSettings());
    org?.id && dispatch(getAutoReplySettings({ orgId: org.id }));
  }, [dispatch, org?.id]);
}
function useCheckMailSettingsValid() {
  const org = useOrg();
  const [mailSettings] = useMailSettings(org?.id);
  const navigate = useNavigate();
  useEffect(() => {
    if (mailSettings?.accountStatus === 'invalid') {
      toast.error(
        `Email account ${mailSettings?.user} authorization has expired, please try to delete and re-authenticate.`,
      );
      navigate('/dashboard/settings', { replace: true });
    }
  }, [mailSettings, navigate]);
}
function useWatchGmailAuthorizeCallback() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (searchParams.get('state') === 'success') {
      navigate('/dashboard/settings', { replace: true });
    }
  }, [navigate, searchParams]);
}
export function useWatchRouting(): null {
  const navigate = useNavigate();
  const org = useOrg();
  const templatesStatus = useTemplateFetchingStatus(org?.id);

  const location = useLocation();
  useEffect(() => {
    if (templatesStatus === FETCHING_STATUS.DONE) {
      if (location.pathname === '/dashboard') {
        if (true) {
          navigate('/dashboard/inbox');
        } else {
          navigate('/dashboard/settings/knowledge', { replace: true });
        }
      }
      // TODO check knowledge status
      if (location.pathname === '/dashboard/inbox' && false) {
        navigate('/dashboard/settings/knowledge', { replace: true });
        toast.error(
          'Please add some knowledge bases by uploading a file or entering a website url before going to any specific service!',
        );
      }
    }
  }, [navigate, location, templatesStatus]);
  return null;
}

function SettingsGlobalEffect(): null {
  useGetSettings();
  useWatchGmailAuthorizeCallback();
  useCheckMailSettingsValid();
  return null;
}

export default memo(SettingsGlobalEffect);
