import { IKnowledgeMessage } from '@/types/messages';
import { IKnowledgebase, IKnowledgebaseTages } from '@/types/knowledge';
import { IResponse } from '@/types/settings';
import { axiosGet, axiosPost, axiosPut, axiosDelete } from '@/utils/axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
export const setKnowledgeMessage = createAction<{ message: IKnowledgeMessage }>('knowledge/setKnowledgeMessage');

export const getKnowledge = createAsyncThunk(
  'knowledge/getKnowledge',
  async ({ knowledge_id }: { knowledge_id: string }): Promise<IKnowledgeMessage[] | undefined> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT_2}knowledge`, { params: { knowledge_id } });
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const getKnowledgebase = createAsyncThunk(
  'knowledge/getKnowledgebase',
  async ({
    type,
    status,
    page,
    pageSize,
    search,
    category,
    tag,
  }: {
    type?: string;
    status?: string;
    page?: number;
    pageSize?: number;
    search?: string;
    category?: string;
    tag?: string;
  }): Promise<IResponse<IKnowledgebase[]>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}knowledgebase`, {
        params: {
          type,
          status: status ? status : 'published',
          page,
          pageSize,
          search,
          category,
          tag,
        },
      });
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const addknowledgebase = createAsyncThunk(
  'knowledge/addknowledgebase',
  async (
    {
      activeEndDate,
      activeInfinity,
      activeStartDate,
      content,
      tags,
      source,
      status,
      title,
      category,
    }: {
      activeEndDate: string;
      activeInfinity: boolean;
      activeStartDate: string;
      content: string;
      category: string;
      tags: (string | null)[];
      source?: string;
      status: string;
      title: string;
    },
    { dispatch },
  ): Promise<IResponse<IKnowledgebaseTages[]>> => {
    try {
      const result = await axiosPost(`${process.env.REACT_APP_API_ROOT_V2}knowledgebase`, {
        activeEndDate,
        activeInfinity,
        activeStartDate,
        content,
        category,
        tags,
        source,
        status,
        title,
      });
      dispatch(getknowledgebaseTags());
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const getknowledgebaseDetail = createAsyncThunk(
  'knowledge/getknowledgebaseDetail',
  async ({ knowledge_id }: { knowledge_id: string }): Promise<IResponse<IKnowledgebaseTages[]>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}knowledgebase/${knowledge_id}`);

      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const updateknowledgebase = createAsyncThunk(
  'knowledge/updateknowledgebase',
  async (
    {
      knowledgeId,
      activeEndDate,
      activeInfinity,
      activeStartDate,
      content,
      category,
      tags,
      source,
      status,
      title,
    }: {
      knowledgeId: string;
      activeEndDate: string;
      activeInfinity: boolean;
      activeStartDate: string;
      content: string;
      category: string;
      tags: (string | null)[];
      source?: string;
      status: string;
      title: string;
    },
    { dispatch },
  ): Promise<IResponse<IKnowledgebaseTages[]>> => {
    try {
      const result = await axiosPut(`${process.env.REACT_APP_API_ROOT_V2}knowledgebase/${knowledgeId}`, {
        activeEndDate,
        activeInfinity,
        activeStartDate,
        content,
        category,
        tags,
        source,
        status,
        title,
      });
      dispatch(getknowledgebaseTags());
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const deleteknowledgebase = createAsyncThunk(
  'knowledge/deleteknowledgebase',
  async (
    {
      knowledgeId,
    }: {
      knowledgeId: string;
    },
    { dispatch },
  ): Promise<IResponse<IKnowledgebaseTages[]>> => {
    try {
      const result = await axiosDelete(`${process.env.REACT_APP_API_ROOT_V2}knowledgebase/${knowledgeId}`);
      dispatch(getknowledgebaseTags());
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const getknowledgebaseTags = createAsyncThunk(
  'knowledge/getknowledgeDirections',
  async (): Promise<IResponse<IKnowledgebaseTages[]>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}knowledgebase/tags`);
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const deleteKnowledgebaseTags = createAsyncThunk(
  'knowledge/deleteKnowledgebaseTags',
  async ({tag_id}: {tag_id: string}, { dispatch }): Promise<IResponse<IKnowledgebaseTages[]>> => {
    try {
      const result = await axiosDelete(`${process.env.REACT_APP_API_ROOT_V2}knowledgebase/tags`, {
        tag_id
      });
      dispatch(getknowledgebaseTags());
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const addknowledgebaseTags = createAsyncThunk(
  'knowledge/addknowledgebaseTags',
  async (data): Promise<IResponse<IKnowledgebaseTages[]>> => {
    try {
      const result = await axiosPost(`${process.env.REACT_APP_API_ROOT_V2}knowledgebase/tags`, data);
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
