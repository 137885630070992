import {
  ItemStatus,
  ListStatus,
  ObjectStatus,
  getDefaultListStatus,
  getDefaultObjectStatus,
  setFulfilledItemStatus,
  setFulfilledListStatus,
  setFulfilledObjectStatus,
} from '@/types/redux';
import {
  IAutoReplySettings,
  IKnowledgeBase,
  IMailSetting,
  IMailSignature,
  IOrganization,
  ISocialSetting,
  ITemplate,
} from '@/types/settings';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  deleteOrgMailSettings,
  deleteSignature,
  deleteTemplate,
  setDefaultTemplate,
  getOrg,
  getOrgMailSettings,
  getSignature,
  getTemplate,
  saveOrgMailSettings,
  saveSignature,
  saveTemplate,
  updateTemplate,
  getOrgSocialSettings,
  deleteOrgSocialSettings,
  saveOrgSocialSettings,
  getAutoReplySettings,
  updateAutoReplySetting,
} from './actions';
import { AppState } from '../store';
import { ERROR_CODE, FETCHING_STATUS } from '@/constants';
import { toast } from 'react-toastify';

export interface ISettingsState {
  org: IOrganization | undefined;
  orgMailSettings: {
    [org: string]: ListStatus<IMailSetting>;
  };
  orgMailSavingStatus: {
    [org: string]: FETCHING_STATUS;
  };
  orgSignature: {
    [org: string]: ListStatus<IMailSignature>;
  };
  orgSignatureStatus: {
    [org: string]: { [id: string]: FETCHING_STATUS };
  };
  orgTemplate: {
    [org: string]: ListStatus<ITemplate>;
  };
  orgTemplateStatus: {
    [org: string]: { [id: string]: FETCHING_STATUS };
  };
  orgTemplateSettingStatus: {
    [org: string]: { [id: string]: FETCHING_STATUS };
  };
  orgMailSettingDeletingStatus: {
    [org: string]: { [id: string]: FETCHING_STATUS };
  };

  orgDataSource: {
    [org: string]: { [id: string]: IKnowledgeBase };
  };
  orgSocialSettings: ObjectStatus<ISocialSetting>;
  orgSocialDeletingStatus: FETCHING_STATUS;
  orgSocialSavingStatus: FETCHING_STATUS;
  orgAutoReplySettings: { [orgId: string]: ItemStatus<IAutoReplySettings> };
}

const initialState: ISettingsState = {
  org: undefined,
  orgMailSettings: {},
  orgMailSettingDeletingStatus: {},
  orgMailSavingStatus: {},
  orgSignature: {},
  orgSignatureStatus: {},
  orgTemplate: {},
  orgTemplateStatus: {},
  orgTemplateSettingStatus: {},
  orgDataSource: {},
  orgSocialSettings: {
    status: FETCHING_STATUS.INIT,
  },
  orgSocialDeletingStatus: FETCHING_STATUS.INIT,
  orgSocialSavingStatus: FETCHING_STATUS.INIT,
  orgAutoReplySettings: {},
};

export const settingsSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getOrg.fulfilled, (state, { payload }) => {
        state.org = payload;
      })
      .addCase(getOrgMailSettings.pending, (state, { meta }) => {
        _.set(state.orgMailSettings, [meta.arg.org || ''], getDefaultListStatus(FETCHING_STATUS.FETCHING));
      })
      .addCase(getOrgMailSettings.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          _.set(state.orgMailSettings, [meta.arg.org], setFulfilledListStatus(payload));
        }
      })
      .addCase(getOrgMailSettings.rejected, (state, { meta }) => {
        _.set(state.orgMailSettings, [meta.arg.org || ''], getDefaultListStatus(FETCHING_STATUS.DONE));
      })
      .addCase(saveOrgMailSettings.pending, (state, { meta }) => {
        _.set(state.orgMailSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.FETCHING);
      })
      .addCase(saveOrgMailSettings.rejected, (state, { meta }) => {
        _.set(state.orgMailSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.DONE);
      })
      .addCase(saveOrgMailSettings.fulfilled, (state, { meta, payload }) => {
        if (payload && payload.errno !== 0) {
          toast.error('Mail settings incorrect');
        } else {
          _.set(state.orgMailSettings, [meta.arg.org || '', 'list', payload.data.id], payload.data);
        }
        _.set(state.orgMailSavingStatus, [meta.arg.org || ''], FETCHING_STATUS.DONE);
      })
      .addCase(deleteOrgMailSettings.pending, (state, { meta }) => {
        _.set(state.orgMailSettingDeletingStatus, [meta.arg.org || '', meta.arg.setting.id], FETCHING_STATUS.FETCHING);
      })
      .addCase(deleteOrgMailSettings.rejected, (state, { meta }) => {
        _.set(state.orgMailSettingDeletingStatus, [meta.arg.org || '', meta.arg.setting.id], FETCHING_STATUS.DONE);
      })
      .addCase(deleteOrgMailSettings.fulfilled, (state, { meta, payload }) => {
        if (payload && payload.errno !== 0) {
          toast.error(payload.errmsg);
        }
        _.set(state.orgMailSettingDeletingStatus, [meta.arg.org || '', meta.arg.setting.id], FETCHING_STATUS.DONE);
      })
      .addCase(saveTemplate.pending, (state, { meta }) => {
        _.set(state.orgTemplateStatus, [meta.arg.org || '', 'default'], FETCHING_STATUS.FETCHING);
      })
      .addCase(saveTemplate.fulfilled, (state, { meta, payload }) => {
        if (payload.errno === ERROR_CODE.ERROR) {
          toast.error(payload.errmsg);
        } else if (payload) {
          _.set(state.orgTemplate, [meta.arg.org || '', 'list', payload.data.id || ''], payload.data);
        }
        _.set(state.orgTemplateStatus, [meta.arg.org || '', 'default'], FETCHING_STATUS.DONE);
      })
      .addCase(saveTemplate.rejected, (state, { meta }) => {
        _.set(state.orgTemplateStatus, [meta.arg.org || '', 'default'], FETCHING_STATUS.DONE);
      })
      .addCase(updateTemplate.pending, (state, { meta }) => {
        _.set(state.orgTemplateStatus, [meta.arg.org || '', meta.arg.template.id || ''], FETCHING_STATUS.FETCHING);
      })
      .addCase(updateTemplate.fulfilled, (state, { meta, payload }) => {
        if (payload.errno === ERROR_CODE.ERROR) {
          toast.error(payload.errmsg);
        } else if (payload) {
          _.set(state.orgTemplate, [meta.arg.org || '', 'list', payload.data.id || ''], payload.data);
        }
        _.set(state.orgTemplateStatus, [meta.arg.org || '', meta.arg.template.id || ''], FETCHING_STATUS.DONE);
      })
      .addCase(updateTemplate.rejected, (state, { meta }) => {
        _.set(state.orgTemplateStatus, [meta.arg.org || '', meta.arg.template.id || ''], FETCHING_STATUS.DONE);
      })
      .addCase(deleteTemplate.pending, (state, { meta }) => {
        _.set(state.orgTemplateStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.FETCHING);
      })
      .addCase(deleteTemplate.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          const list = _.get(state.orgTemplate, [meta.arg.org || '', 'list']);
          const newList = _.omit(list, [meta.arg.id]);
          _.set(state.orgTemplate, [meta.arg.org || '', 'list'], newList);
        }
        _.set(state.orgTemplateStatus, [meta.arg.org || '', meta.arg.id || ''], FETCHING_STATUS.DONE);
      })
      .addCase(deleteTemplate.rejected, (state, { meta }) => {
        _.set(state.orgTemplateStatus, [meta.arg.org || '', meta.arg.id || ''], FETCHING_STATUS.DONE);
      })

      .addCase(setDefaultTemplate.pending, (state, { meta }) => {
        _.set(state.orgTemplateSettingStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.FETCHING);
      })
      .addCase(setDefaultTemplate.fulfilled, (state, { meta }) => {
        _.set(state.orgTemplateSettingStatus, [meta.arg.org || '', meta.arg.id || ''], FETCHING_STATUS.DONE);
      })
      .addCase(setDefaultTemplate.rejected, (state, { meta }) => {
        _.set(state.orgTemplateSettingStatus, [meta.arg.org || '', meta.arg.id || ''], FETCHING_STATUS.DONE);
      })
      .addCase(getTemplate.pending, (state, { meta }) => {
        _.set(state.orgTemplateStatus, [meta.arg.org || ''], getDefaultListStatus(FETCHING_STATUS.FETCHING));
      })
      .addCase(getTemplate.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          _.set(state.orgTemplate, [meta.arg.org || ''], setFulfilledListStatus(payload.data));
        }
      })
      .addCase(deleteSignature.pending, (state, { meta }) => {
        _.set(state.orgSignatureStatus, [meta.arg.org || '', meta.arg.id], FETCHING_STATUS.FETCHING);
      })
      .addCase(deleteSignature.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          const list = _.get(state.orgSignature, [meta.arg.org || '', 'list']);
          const newList = _.omit(list, [meta.arg.id]);
          _.set(state.orgSignature, [meta.arg.org || '', 'list'], newList);
        }
        _.set(state.orgSignatureStatus, [meta.arg.org || '', meta.arg.id || ''], FETCHING_STATUS.DONE);
      })
      .addCase(deleteSignature.rejected, (state, { meta }) => {
        _.set(state.orgSignatureStatus, [meta.arg.org || '', meta.arg.id || ''], FETCHING_STATUS.DONE);
      })
      .addCase(saveSignature.pending, (state, { meta }) => {
        _.set(state.orgSignatureStatus, [meta.arg.org || '', 'default'], FETCHING_STATUS.FETCHING);
      })
      .addCase(saveSignature.fulfilled, (state, { meta, payload }) => {
        if (payload.errno === ERROR_CODE.ERROR) {
          toast.error(payload.errmsg);
        } else if (payload) {
          _.set(state.orgSignature, [meta.arg.org || '', 'list', payload.data.id || ''], payload.data);
        }
        _.set(state.orgSignatureStatus, [meta.arg.org || '', 'default'], FETCHING_STATUS.DONE);
      })
      .addCase(saveSignature.rejected, (state, { meta }) => {
        _.set(state.orgSignatureStatus, [meta.arg.org || '', 'default'], FETCHING_STATUS.DONE);
      })
      .addCase(getSignature.rejected, (state, { meta }) => {
        _.set(state.orgSignatureStatus, [meta.arg.org || ''], getDefaultListStatus(FETCHING_STATUS.DONE));
      })
      .addCase(getSignature.pending, (state, { meta }) => {
        _.set(state.orgSignatureStatus, [meta.arg.org || ''], getDefaultListStatus(FETCHING_STATUS.FETCHING));
      })
      .addCase(getSignature.fulfilled, (state, { meta, payload }) => {
        if (payload) {
          _.set(state.orgSignature, [meta.arg.org || ''], setFulfilledListStatus(payload.data));
        }
      })
      .addCase(getOrgSocialSettings.pending, (state) => {
        state.orgSocialSettings = getDefaultObjectStatus(FETCHING_STATUS.FETCHING);
      })
      .addCase(getOrgSocialSettings.rejected, (state) => {
        state.orgSocialSettings = getDefaultObjectStatus(FETCHING_STATUS.DONE);
      })
      .addCase(getOrgSocialSettings.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.orgSocialSettings = setFulfilledObjectStatus(payload.data);
        } else {
          state.orgSocialSettings = getDefaultObjectStatus(FETCHING_STATUS.DONE);
        }
      })
      .addCase(deleteOrgSocialSettings.pending, (state) => {
        state.orgSocialDeletingStatus = FETCHING_STATUS.FETCHING;
      })
      .addCase(deleteOrgSocialSettings.rejected, (state) => {
        state.orgSocialDeletingStatus = FETCHING_STATUS.DONE;
      })
      .addCase(deleteOrgSocialSettings.fulfilled, (state, { payload }) => {
        if (payload) {
          state.orgSocialDeletingStatus = FETCHING_STATUS.DONE;
        }
      })
      .addCase(saveOrgSocialSettings.pending, (state) => {
        state.orgSocialSavingStatus = FETCHING_STATUS.FETCHING;
      })
      .addCase(saveOrgSocialSettings.rejected, (state) => {
        state.orgSocialSavingStatus = FETCHING_STATUS.DONE;
      })
      .addCase(saveOrgSocialSettings.fulfilled, (state, { payload }) => {
        if (payload) {
          state.orgSocialSavingStatus = FETCHING_STATUS.DONE;
        }
      });
    builder
      .addCase(getAutoReplySettings.pending, (state, { meta }) => {
        _.set(state.orgAutoReplySettings, [meta.arg.orgId, 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getAutoReplySettings.fulfilled, (state, { meta, payload }) => {
        _.set(state.orgAutoReplySettings, [meta.arg.orgId], setFulfilledItemStatus(payload));
      })
      .addCase(updateAutoReplySetting.fulfilled, (state, { meta, payload }) => {
        _.set(state.orgAutoReplySettings, [meta.arg.orgId], setFulfilledItemStatus(payload));
      });
  },
});
export const selectOrg = (state: AppState): IOrganization | undefined => state.settings.org;
export const selectSavingMailStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgMailSavingStatus, [org || ''], FETCHING_STATUS.DONE);
export const selectDeletingMailStatus =
  (org: string | undefined, id: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgMailSettingDeletingStatus, [org || '', id || ''], FETCHING_STATUS.DONE);

export const selectMailSettings =
  (org: string | undefined) =>
  (state: AppState): Record<string, IMailSetting> | undefined =>
    _.get(state.settings.orgMailSettings, [org || '', 'list']);
export const selectMailSettingsStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgMailSettings, [org || '', 'status']);

export const selectDataSource =
  (org: string | undefined, id: string) =>
  (state: AppState): IKnowledgeBase | undefined =>
    _.get(state.settings.orgDataSource, [org || '', id || '']);

export const selectTemplate =
  (org: string | undefined) =>
  (state: AppState): Record<string, ITemplate> =>
    _.get(state.settings.orgTemplate, [org || '', 'list']);
export const selectTemplateStatus =
  (org: string | undefined, id: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgTemplateStatus, [org || '', id || 'default']);
export const setTemplateStatus =
  (org: string | undefined, id: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgTemplateSettingStatus, [org || '', id || 'default']);
export const selectTemplateFetchingStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgTemplate, [org || '', 'status']);
export const selectSignature =
  (org: string | undefined) =>
  (state: AppState): Record<string, IMailSignature> =>
    _.get(state.settings.orgSignature, [org || '', 'list']);
export const selectSignatureStatus =
  (org: string | undefined, id: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgSignature, [org || '', id || 'default']);
export const selectSignatureFetchingStatus =
  (org: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.settings.orgSignature, [org || '', 'status']);

export const selectSocialSettings = (state: AppState): ObjectStatus<ISocialSetting> => state.settings.orgSocialSettings;
export const selectSocialSettingsStatus =
  () =>
  (state: AppState): FETCHING_STATUS =>
    state.settings.orgSocialSettings.status;

export const selectDeletingSocialStatus =
  () =>
  (state: AppState): FETCHING_STATUS =>
    state.settings.orgSocialDeletingStatus;

export const selectSavingSocialStatus =
  () =>
  (state: AppState): FETCHING_STATUS =>
    state.settings.orgSocialSavingStatus;
export const selectOrgAutoReplySettings =
  (orgId: string | undefined) =>
  (state: AppState): IAutoReplySettings | undefined =>
    _.get(state.settings.orgAutoReplySettings, [orgId || '', 'item']);
export const selectOrgAutoReplySettingsStatus =
  (orgId: string | undefined) =>
  (state: AppState): FETCHING_STATUS | undefined =>
    _.get(state.settings.orgAutoReplySettings, [orgId || '', 'status']);

export default settingsSlice.reducer;
