export enum TICKET_PRIORITY {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}
export enum TICKET_STATUS {
  ALL = 'all',
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}
