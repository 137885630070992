/**
 * @description Component-UserGuidance
 */
import React, { FC, useState } from 'react';
import './index.less';
import {
  //TODO add useAllSettingsDone
  // useAllSettingsDone,
  // useKnowledgeBases,
  useMailSettings,
  useOrg,
  useSignature,
  useTemplates,
} from '@/features/settings/hooks';
import { useNavigate } from 'react-router-dom';
import { SETTINGS_TABS } from '@/constants';
import { ReactComponent as FilesIcon } from '@/assets/svg/files_icon.svg';
import { ReactComponent as SelIcon } from '@/assets/svg/sel_icon.svg';
import { ReactComponent as AddIcon } from '@/assets/svg/add_icon.svg';
import { ReactComponent as RightIcon } from '@/assets/svg/right_icon.svg';
import { ReactComponent as RolexIcon } from '@/assets/svg/rolex_icon.svg';
import { ReactComponent as SignatureIcon } from '@/assets/svg/signature_icon.svg';
import { ReactComponent as CompletionIcon } from '@/assets/svg/completion_icon.svg';
import { ReactComponent as EmailIcon } from '@/assets/svg/email_icon.svg';
interface IPropTypes {
  className?: string;
}
const UserGuidance: FC<IPropTypes> = function () {
  const org = useOrg();
  const [mailSettings] = useMailSettings(org?.id);
  // const knowledgeBases = useKnowledgeBases(org?.id);
  const templates = useTemplates(org?.id);
  const [signatures] = useSignature(org?.id);
  const navigate = useNavigate();
  // const allSettingsDone = useAllSettingsDone(org?.id);
  const settingsRoute = '/dashboard/settings';

  const gotoSettings = (route: string, tab?: string) => {
    if (tab) {
      navigate(route, { state: { tab } });
      return;
    }

    navigate(route);
  };
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <div className="cor-user-guidance">
        <div className="cor-user-guidance-title">
          <div className="cor-user-guidance-title-1">
            <span>Welcome to Cornerstone! 👋</span> <span>Let&apos;s get started</span>
          </div>
          <div className="cor-user-guidance-title-2">
            Get set up in under <span>5 minutes</span>. Check off what you&apos;ve completed!
          </div>
        </div>
        <div className="cor-user-guidance-content">
          <div
            className={`cor-user-guidance-content-box1 cor-user-guidance-item ${[]?.length ? 'active' : ''}`}
            onClick={() => navigate(`${settingsRoute}/${SETTINGS_TABS.KNOWLEDGE}`)}>
            <div className="item-top">
              <FilesIcon></FilesIcon>
              {[]?.length ? <SelIcon></SelIcon> : <></>}
            </div>
            <div className="item-msg">
              <div className="item-msg-1">Add Knowledge Files</div>
              <div className="item-msg-2">
                Upload your knowledge files to build a robust knowledge base. This helps Cornerstone provide intelligent
                and context-aware replies.
              </div>
              <div className="item-msg-3">
                {' '}
                <span>Go to Add {[]?.length ? ' More' : ''}</span>
                <AddIcon></AddIcon>
              </div>
            </div>
          </div>
          <div
            className={`cor-user-guidance-content-box2 cor-user-guidance-item ${templates?.length ? 'active' : ''}`}
            onClick={() => navigate(`${settingsRoute}/${SETTINGS_TABS.TEMPLATE}`)}>
            <div className="item-top">
              <RolexIcon></RolexIcon>
              {templates?.length ? <SelIcon></SelIcon> : <></>}
            </div>
            <div className="item-msg">
              <div className="item-msg-1">Select and Create Roles</div>
              <div className="item-msg-2">
                Define roles to tailor Cornerstone&apos;s AI tone. Whether it&apos;s customer support or sales, ensure
                your communication is pitch-perfect.
              </div>
              <div className="item-msg-3">
                {' '}
                <span>Go to Add {templates?.length ? ' More' : ''}</span>
                <RightIcon></RightIcon>
              </div>
            </div>
          </div>
        </div>
        <div className="cor-user-guidance-content">
          <div
            className={`cor-user-guidance-content-box2 cor-user-guidance-item ${signatures ? 'active' : ''}`}
            onClick={() => navigate(`${settingsRoute}/${SETTINGS_TABS.CONNECTIONS}`)}>
            <div className="item-top">
              <SignatureIcon></SignatureIcon>
              {signatures?.id ? <SelIcon></SelIcon> : <></>}
            </div>
            <div className="item-msg">
              <div className="item-msg-1">Design Email Signature</div>
              <div className="item-msg-2">
                Craft your email signature for a personalized touch in your automated responses. Stand out with every
                email you send.
              </div>
              <div className="item-msg-3">
                {' '}
                <span>{signatures?.id ? 'Go to Edit' : 'Go to Design'}</span>
                <RightIcon></RightIcon>
              </div>
            </div>
          </div>
          <div
            className={`cor-user-guidance-content-box3 cor-user-guidance-item ${mailSettings ? 'active' : ''}`}
            onClick={() => navigate(`${settingsRoute}/${SETTINGS_TABS.CONNECTIONS}`)}>
            <div className="item-top">
              <EmailIcon></EmailIcon>
              {mailSettings ? <SelIcon></SelIcon> : <></>}
            </div>
            <div className="item-msg" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
              <div className="item-msg-1 ">Connect your email</div>
              <div className="item-msg-2">
                Authorize your email to empower Cornerstone with generating AI-driven responses for your email
                inquiries. Streamline your communication efficiently.Your anonymized data may be shared with our
                third-part Large Language Learning Model partners.
              </div>
              <div className={`item-msg-3-connect item-msg-3  ${isHovered ? 'hovered' : ''}`}>
                {' '}
                <span>Connect{mailSettings ? 'ed' : ''}</span>
              </div>
            </div>
          </div>
          <div
            className={`cor-user-guidance-content-box2 cor-user-guidance-item ${true ? 'active' : ''}`}
            onClick={() => gotoSettings('/dashboard/inbox')}>
            <div className="item-top">
              <CompletionIcon></CompletionIcon>
              {true ? <SelIcon></SelIcon> : <></>}
            </div>
            <div className="item-msg">
              <div className="item-msg-1">Completion 🎉</div>
              <div className="item-msg-2">
                Setup Complete! You’re all set to take your email interactions to the next level with Cornerstone’s AI.
              </div>
              <div className="item-msg-3">
                {' '}
                <span>Go to Inbox</span>
                <RightIcon></RightIcon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserGuidance;
