import { AUTH_ACCESS_TOKEN } from '@/constants';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export async function axiosGet(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
  return await axios.get(url, {
    ...config,
    headers: { ...config?.headers, authorization: `Bearer ${localStorage.getItem(AUTH_ACCESS_TOKEN)}` },
  });
}
export async function axiosPost(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
  return await axios.post(url, data, {
    ...config,
    headers: { ...config?.headers, authorization: `Bearer ${localStorage.getItem(AUTH_ACCESS_TOKEN)}` },
  });
}

export async function axiosPut(url: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
  return await axios.put(url, data, {
    ...config,
    headers: { ...config?.headers, authorization: `Bearer ${localStorage.getItem(AUTH_ACCESS_TOKEN)}` },
  });
}
export async function axiosDelete(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse> {
  return await axios.delete(url, {
    ...config,
    data,
    headers: { ...config?.headers, authorization: `Bearer ${localStorage.getItem(AUTH_ACCESS_TOKEN) || ''}` },
  });
}

/**
 * polling func
 * @param {Function} func
 * @param {Number} interval
 */
export const pollingFunc = (func: () => void, interval = 3000, immediately = true): NodeJS.Timeout => {
  if (immediately) {
    func();
  }
  return setInterval(() => {
    func();
  }, interval);
};
