import { useMemo } from 'react';
import { useAppSelector } from '../hooks';
import {
  selectMailSettings,
  selectOrg,
  selectSavingMailStatus,
  selectDeletingMailStatus,
  selectMailSettingsStatus,
  selectTemplate,
  selectTemplateStatus,
  selectTemplateFetchingStatus,
  selectDataSource,
  selectSignature,
  selectSignatureFetchingStatus,
  selectSignatureStatus,
  setTemplateStatus,
  selectSocialSettings,
  selectSocialSettingsStatus,
  selectDeletingSocialStatus,
  selectSavingSocialStatus,
  selectOrgAutoReplySettings,
  selectOrgAutoReplySettingsStatus,
} from './slice';
import {
  IAutoReplySettings,
  // IDataSource,
  IKnowledgeBase,
  IMailSetting,
  IMailSignature,
  IOrganization,
  ISocialSetting,
  ITemplate,
} from '@/types/settings';
import _ from 'lodash';
import { FETCHING_STATUS } from '@/constants';
import { ObjectStatus } from '@/types/redux';

export function useMailSettings(org: string | undefined): IMailSetting[] {
  const settings = useAppSelector(selectMailSettings(org));
  return useMemo(() => _.values(settings), [settings]);
}
export function useMailSettingsStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectMailSettingsStatus(org));
  return useMemo(() => status, [status]);
}
export function useMailSavingStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectSavingMailStatus(org));
  return useMemo(() => status, [status]);
}
export function useMailDeletingStatus(org: string | undefined, id: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectDeletingMailStatus(org, id));
  return useMemo(() => status, [status]);
}

export function useDataSource(org: string | undefined, id: string): IKnowledgeBase | undefined {
  const dataSource = useAppSelector(selectDataSource(org, id));
  return useMemo(() => dataSource, [dataSource]);
}

export function useSignature(org: string | undefined): IMailSignature[] {
  const signatures = useAppSelector(selectSignature(org));
  return useMemo(() => _.values(signatures), [signatures]);
}
export function useSignatureStatus(org: string | undefined, id?: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectSignatureStatus(org, id));
  return useMemo(() => status, [status]);
}
export function useSignatureFetchingStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectSignatureFetchingStatus(org));
  return useMemo(() => status, [status]);
}
export function useTemplates(org: string | undefined): ITemplate[] {
  const templates = useAppSelector(selectTemplate(org));
  return useMemo(() => _.orderBy(_.values(templates), ['updatedAt'], ['desc']), [templates]);
}
export function useTemplateStatus(org: string | undefined, id?: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectTemplateStatus(org, id));
  return useMemo(() => status, [status]);
}
export function useTemplateSettingStatus(org: string | undefined, id?: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(setTemplateStatus(org, id));
  return useMemo(() => status, [status]);
}
export function useTemplateFetchingStatus(org: string | undefined): FETCHING_STATUS {
  const status = useAppSelector(selectTemplateFetchingStatus(org));
  return useMemo(() => status, [status]);
}
export function useOrg(): IOrganization | undefined {
  const org = useAppSelector(selectOrg);
  return useMemo(() => org, [org]);
}

export function useSocialSettings(): ObjectStatus<ISocialSetting> {
  const social = useAppSelector(selectSocialSettings);
  return useMemo(() => social, [social]);
}

export function useSocialSettingsStatus(): FETCHING_STATUS {
  const status = useAppSelector(selectSocialSettingsStatus());
  return useMemo(() => status, [status]);
}

export function useSocialDeletingStatus(): FETCHING_STATUS {
  const status = useAppSelector(selectDeletingSocialStatus());
  return useMemo(() => status, [status]);
}
export function useSocialSavingStatus(): FETCHING_STATUS {
  const status = useAppSelector(selectSavingSocialStatus());
  return useMemo(() => status, [status]);
}
export function useAutoReplySettings(orgId: string | undefined): IAutoReplySettings | undefined {
  const settings = useAppSelector(selectOrgAutoReplySettings(orgId));
  return useMemo(() => settings, [settings]);
}
export function useAutoReplySettingsStatus(orgId: string | undefined): FETCHING_STATUS | undefined {
  const status = useAppSelector(selectOrgAutoReplySettingsStatus(orgId));
  return useMemo(() => status, [status]);
}
