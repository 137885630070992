import { memo, useEffect } from 'react';
import { useAppDispatch } from '../hooks';
import { getknowledgebaseTags } from './actions';

function useGetDocumentknowledge() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getknowledgebaseTags());
  }, [dispatch]);
}



function DocumentknowledgeGlobalEffect(): null {
  useGetDocumentknowledge();
  return null;
}

export default memo(DocumentknowledgeGlobalEffect);
