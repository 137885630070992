/**
 * @description Component-PlaygroundReference
 */
import React, { FC } from 'react';
import './index.less';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const PlaygroundReference: FC<IPropTypes> = function (props) {
  return <div className="cor-playground-reference"></div>;
};

export default PlaygroundReference;
