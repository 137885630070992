/**
 * @description Component-ChatContent
 */
import { Empty, Skeleton } from 'antd';
import React, { FC, useCallback, useEffect, useRef } from 'react';

import { FETCHING_STATUS } from '@/constants';
import { useOrg } from '@/features/settings/hooks';
import { useMetaPostCommentReplies, useMetaPostsUserCommentsStatus } from '@/features/inbox/hooks';
import InfiniteScroller from 'react-infinite-scroller';

import ChatContentItem from './ChatContentItem';
import './index.less';
import { IMetaComment } from '@/types/inbox';
import _ from 'lodash';

interface IPropTypes {
  className?: string;
  comment: IMetaComment;
  selectComment?: (comment: IMetaComment) => void;
}

const ChatContent: FC<IPropTypes> = function ({ comment, selectComment }) {
  const org = useOrg();
  const commentsListStatus = useMetaPostsUserCommentsStatus(org?.id, comment?.id);
  const replies = useMetaPostCommentReplies(org?.id, comment?.id);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const onLoadMore = useCallback(() => {
    // console.log(1);
  }, []);

  const scrollToBottom = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: scrollContainerRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [replies]);

  return (
    <div className="cor-meta-chat-content" ref={scrollContainerRef}>
      <InfiniteScroller
        useWindow={false}
        initialLoad={false}
        hasMore={false}
        getScrollParent={() => scrollContainerRef.current}
        threshold={10}
        loadMore={onLoadMore}>
        {commentsListStatus !== FETCHING_STATUS.FETCHING && !replies?.length && <Empty description="No messages" />}
        {(commentsListStatus === FETCHING_STATUS.FETCHING || !commentsListStatus) &&
          (!replies?.length || replies.length < 2) && (
            <>
              <Skeleton active={true} title={false} paragraph={{ rows: 5 }} />
            </>
          )}
        {!!replies?.length &&
          _.tail(replies).map((c) => (
            <ChatContentItem
              selectComment={selectComment}
              fromId={comment.from?.id}
              key={c.id}
              className="cor-meta-chat-contentItem"
              item={c}
            />
          ))}
      </InfiniteScroller>
    </div>
  );
};

export default ChatContent;
