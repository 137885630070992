import { IKnowledgeMessage } from '@/types/messages';
import { useAppSelector } from '../hooks';
import {
  selectKnowledge,
  selectKnowledgeMessages,
  selectKnowledgeSavingStatus,
  selectKnowledgeStatus,
  selectReplying,
} from './slice';
import { IKnowledge } from '@/types/knowledge';
import { FETCHING_STATUS } from '@/constants';

export function useKnowledgeReplying(): boolean {
  return useAppSelector(selectReplying);
}
export function useKnowledgeMessages(): IKnowledgeMessage[] | undefined {
  return useAppSelector(selectKnowledgeMessages);
}
export function useKnowledgeDirections(): IKnowledge[] | undefined {
  return useAppSelector(selectKnowledge);
}

export function useKnowledgeDirectionsStatus(): FETCHING_STATUS | undefined {
  return useAppSelector(selectKnowledgeStatus);
}

export function useKnowledgeDirectionsSavingStatus(): FETCHING_STATUS {
  return useAppSelector(selectKnowledgeSavingStatus);
}
