import React, { FC, useCallback, useState } from 'react';
import './index.less';
import { Button, Upload, Spin } from 'antd';
import { getUploadPresign } from '@/features/settings/actions';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import _ from 'lodash';
import { useAppDispatch } from '@/features/hooks';

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  onUploadSuccess: (fileInfo: { name: string; attach: string }) => void; // Add this prop to pass data to parent
}

const InputUploader: FC<IPropTypes> = function ({ id, onUploadSuccess }) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const getPresignedUrl = useCallback(async () => {
    const url = await dispatch(getUploadPresign()).unwrap();
    return url;
  }, [dispatch]);

  return (
    <div className="cor-input-uploader">
      <Upload
        showUploadList={false}
        customRequest={async ({ file, onError, onSuccess }) => {
          if (file && onError && onSuccess) {
            const fileType = _.get(file, ['type']);
            try {
              setLoading(true);
              const signedUrl = await getPresignedUrl();
              const options = {
                headers: {
                  'Content-Type': fileType,
                },
              };

              const uploadResult = await axios.put(signedUrl, file, options);

              if (uploadResult.status === 200) {
                onSuccess(uploadResult, file);

                // Pass the file information to the parent component
                onUploadSuccess({
                  name: file.name,
                  attach: uploadResult.request.responseURL,
                });
              }
            } catch (error) {
              console.error('上传失败:', error);
              onError(error);
            } finally {
              setLoading(false);
            }
          }
        }}
        name="ticketUpload">
        <Button icon={<UploadOutlined />} loading={loading} disabled={loading}>
          Upload
        </Button>
      </Upload>
    </div>
  );
};

export default InputUploader;
