/**
 * @description Component-SiderLeft
 */
import React, { FC, useState } from 'react';
import './index.less';
import { ReactComponent as InboxIcon } from '@/assets/svg/mail.svg';
import { ReactComponent as InsightIcon } from '@/assets/svg/lightbulb.svg';
// import { ReactComponent as MailIcon } from '@/assets/svg/icon_list_mail.svg';
import { animated } from 'react-spring';
import { ReactComponent as SettingsIcon } from '@/assets/svg/settings.svg';
import { ReactComponent as AnalysisIcon } from '../assets/folder.svg';
import { ReactComponent as TicketsIcon } from '@/assets/svg/keep.svg';
import { ReactComponent as SubIcon } from '@/assets/svg/library_books.svg';
import { ReactComponent as KnowledgeIcon } from '@/assets/svg/icon_book_2.svg';
import { useGetDashboardMenuItem } from '@/hooks';
import { DASHBOARD_MENU_ITEMS } from '@/constants';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import HeaderDropDown from '@/pages/components/HeadMsg';
import logo from '@/assets/images/logo1.png';
import { useOrg } from '@/features/settings/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import Knowledge from '../knowledge';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const SiderLeft: FC<IPropTypes> = function ({}) {
  const [open] = useState(false);
  const navigate = useNavigate();
  const activeKey = useGetDashboardMenuItem();
  // const sideStyle = useSpring({
  //   width: open ? 140 : 224,
  // });
  const { user } = useAuth0();
  const org = useOrg();

  return (
    <animated.div className="cor-sider-left">
      <div className="cor-sider-left-inner">
        <div className="cor-sider-left-inner-section">
          <a className="cor-sider-left-inner-section-log" onClick={() => navigate('/')}>
            <img className="cor-sider-left-inner-section-log-img" src={logo} />
          </a>
          {/* <Link
            to={'home'}
            className={`cor-sider-left-inner-item ${activeKey === DASHBOARD_MENU_ITEMS.HOME ? 'active' : ''}`}>
            <Tooltip title="Home">
              <HomeIcon className="icon" />
              {open && 'Home'}
            </Tooltip>
            <span
              className={`cor-sider-left-inner-item-text1 ${
                activeKey === DASHBOARD_MENU_ITEMS.HOME ? 'cor-sider-left-inner-item-text2' : ''
              }`}>
              Home
            </span>
          </Link> */}
          {/* {org?.isOwner && (
            <Link
              to={'team'}
              className={`cor-sider-left-inner-item ${activeKey === DASHBOARD_MENU_ITEMS.TEAM ? 'active' : ''}`}>
              <Tooltip title="Home">
                <TeamsIcon className="icon" />
                {open && 'Team'}
              </Tooltip>
              <span
                className={`cor-sider-left-inner-item-text1 ${
                  activeKey === DASHBOARD_MENU_ITEMS.TEAM ? 'cor-sider-left-inner-item-text2' : ''
                }`}>
                Team
              </span>
            </Link>
          )} */}
          <Link
            to={'inbox'}
            className={`cor-sider-left-inner-item ${activeKey === DASHBOARD_MENU_ITEMS.INBOX ? 'active' : ''}`}>
            <Tooltip title="Inbox">
              <InboxIcon className="icon" />
              {open && 'Inbox'}
            </Tooltip>
            <span
              className={`cor-sider-left-inner-item-text1 ${
                activeKey === DASHBOARD_MENU_ITEMS.INBOX ? 'cor-sider-left-inner-item-text2' : ''
              }`}>
              Inbox
            </span>
          </Link>
          <Link
            to={'tickets'}
            className={`cor-sider-left-inner-item ${activeKey === DASHBOARD_MENU_ITEMS.TICKETS ? 'active' : ''}`}>
            <Tooltip title="Tickets">
              <TicketsIcon className="icon" />
              {open && 'Tickets'}
            </Tooltip>
            <span
              className={`cor-sider-left-inner-item-text1 ${
                activeKey === DASHBOARD_MENU_ITEMS.TICKETS ? 'cor-sider-left-inner-item-text2' : ''
              }`}>
              Tickets
            </span>
          </Link>
          <Link
            to={'brand-voice'}
            className={`cor-sider-left-inner-item ${activeKey === DASHBOARD_MENU_ITEMS.BRAND_VOICE ? 'active' : ''}`}>
            <Tooltip title="Brand Voice">
              <InsightIcon className="icon" />
              {open && 'Brand Voice'}
            </Tooltip>
            <span
              className={`cor-sider-left-inner-item-text1 ${
                activeKey === DASHBOARD_MENU_ITEMS.BRAND_VOICE ? 'cor-sider-left-inner-item-text2' : ''
              }`}>
              Brand Voice
            </span>
          </Link>
          <Link
            to={'knowledge'}
            className={`cor-sider-left-inner-item ${activeKey === DASHBOARD_MENU_ITEMS.KNOWLEDGE ? 'active' : ''}`}>
            <Tooltip title="knowledge">
            <KnowledgeIcon className="icon" />
              {open && 'knowledge'}
            </Tooltip>
            <span
              className={`cor-sider-left-inner-item-text1 ${
                activeKey === DASHBOARD_MENU_ITEMS.KNOWLEDGE ? 'cor-sider-left-inner-item-text2' : ''
              }`}>
              Knowledge
            </span>
          </Link>
          {/* <Link
            to={'plugin'}
            className={`cor-sider-left-inner-item ${activeKey === DASHBOARD_MENU_ITEMS.PLUGIN ? 'active' : ''}`}>
            <Tooltip title="Plugin">
              <PluginIcon className="icon" />
              {open && 'Plugin'}
            </Tooltip>
            <span
              className={`cor-sider-left-inner-item-text1 ${
                activeKey === DASHBOARD_MENU_ITEMS.PLUGIN ? 'cor-sider-left-inner-item-text2' : ''
              }`}>
              Plugin
            </span>
          </Link> */}
          {user?.email?.trim().endsWith('@cornerstone.chat') && (
            <Link
              to={'analysis'}
              className={`cor-sider-left-inner-item ${activeKey === DASHBOARD_MENU_ITEMS.ANALYSIS ? 'active' : ''}`}>
              <Tooltip title="Plugin">
                <AnalysisIcon className="icon" /> {open && 'Analysis'}{' '}
              </Tooltip>
              <span
                className={`cor-sider-left-inner-item-text1 ${
                  activeKey === DASHBOARD_MENU_ITEMS.ANALYSIS ? 'cor-sider-left-inner-item-text2' : ''
                }`}>
                Analysis
              </span>
            </Link>
          )}
          <Link
            to={'settings'}
            className={`cor-sider-left-inner-item ${activeKey === DASHBOARD_MENU_ITEMS.SETTINGS ? 'active' : ''}`}>
            <Tooltip title="Settings">
              <SettingsIcon className="icon" />
              {open && 'Settings'}
            </Tooltip>
            <span
              className={`cor-sider-left-inner-item-text1 ${
                activeKey === DASHBOARD_MENU_ITEMS.SETTINGS ? 'cor-sider-left-inner-item-text2' : ''
              }`}>
              Setting
            </span>
          </Link>
          <Link
            to={'subscription'}
            className={`cor-sider-left-inner-item ${activeKey === DASHBOARD_MENU_ITEMS.SUBSCRIPTION ? 'active' : ''}`}>
            <Tooltip title="Subscription">
              <SubIcon className="icon" />
              {open && 'Subscription'}
            </Tooltip>
            <span
              className={`cor-sider-left-inner-item-text1 ${
                activeKey === DASHBOARD_MENU_ITEMS.SUBSCRIPTION ? 'cor-sider-left-inner-item-text2' : ''
              }`}>
              Subscription
            </span>
          </Link>
        </div>
        <div className="cor-sider-left-inner-headMsg">
          <HeaderDropDown />
        </div>
      </div>
    </animated.div>
  );
};

export default SiderLeft;
