import { IAnalysis } from '@/types/analysis';
import { IResponse } from '@/types/settings';
import { axiosGet } from '@/utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAnalysis = createAsyncThunk(
  'analysis/getAnalysis',
  async ({ weekTs }: { orgId: string; weekTs: number }): Promise<IResponse<IAnalysis[]>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT_v2}brand/insight`, {
        params: {
          weekTs,
        },
      });
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
