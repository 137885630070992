/**
 * @description Component-CorNewUploader
 */
import React, { FC, useCallback, useState } from 'react';
import { Button, Upload, UploadFile } from 'antd';
import { getUploadPresign } from '@/features/settings/actions';
import axios from 'axios';
import { useAppDispatch } from '@/features/hooks';
import { FETCHING_STATUS } from '@/constants';
import { useOrg } from '@/features/settings/hooks';
import { ReactComponent as AddIcon } from '@/assets/svg/upload.svg';
import _ from 'lodash';
import { addknowledgebase } from '@/features/knowledge/actions';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const UploadKnowledgeFile: FC<IPropTypes> = function () {
  const [loading, setLoading] = useState(FETCHING_STATUS.INIT);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const org = useOrg();
  const dispatch = useAppDispatch();
  const getPresignedUrl = useCallback(async () => {
    const url = await dispatch(getUploadPresign()).unwrap();
    return url;
  }, [dispatch]);

  const parseFile = useCallback(
    async (url: string, fileName: string) => {
      if (org) {
        try {
          if (url && fileName) {
            await dispatch(
              addknowledgebase({
                category: 'file',
                source: url,
                tags: [fileName],
              }),
            ).unwrap();
            setLoading(FETCHING_STATUS.DONE);
          }
        } catch (e) {
          console.log('🚀 ~ file: index.tsx:97 ~ parseFile ~ e:', e);
          setLoading(FETCHING_STATUS.DONE);
        }
      }
    },
    [dispatch, org],
  );

  return (
    <div className="cor-uploadKnowledgeFile">
      <Upload
        multiple={false}
        beforeUpload={() => {
          setFileList([]);
        }}
        customRequest={async ({ file, onError, onSuccess, onProgress }) => {
          if (file && onError && onSuccess && onProgress) {
            const fullName = _.get(file, 'name', '');
            const fileName = fullName.split('.').slice(0, -1).join('.') || fullName;

            try {
              setLoading(FETCHING_STATUS.FETCHING);
              const signedUrl = await getPresignedUrl();
              if (signedUrl) {
                parseFile(signedUrl, fileName!);
                onSuccess?.(file);
              }
            } catch (error) {
              onError(error);
              setLoading(FETCHING_STATUS.DONE);
            }
          }
        }}
        fileList={fileList}
        showUploadList={false}
        onChange={({ fileList }) => setFileList(fileList)}
        accept=".md,.pdf,.xlsx,.xls,.txt"
        name="Knowledge Base">
        <Button
          className="cor-knowledge-head-btn-enbtn"
          type="primary"
          icon={<AddIcon />}
          disabled={loading === FETCHING_STATUS.FETCHING}
          loading={loading === FETCHING_STATUS.FETCHING}>
          Upload File (.md, pdf, excel,txt)
        </Button>
      </Upload>
    </div>
  );
};

export default UploadKnowledgeFile;
